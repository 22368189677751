<template>
	<v-expansion-panels
		:class="{
			'px-1 elevantion-1 rounded-lg': true,
		}"
		style="margin-top: 1px;"
		v-model="filterExpanded">
		<v-expansion-panel>
			<v-container
				fluid
				class="py-0 pl-8">
				<v-row
					class="wine--text">
					<v-col
						cols="12"
						xl="1"
						lg="2"
						md="3"
						sm="3"
						xs="12"
						class="pl-1 d-flex align-center">
						<span
							class="text-h6 font-weight-bold"
							style="color: #504F4F">
							Filtro
						</span>
					</v-col>
					<v-col
						cols="12"
						xl="11"
						lg="10"
						md="9"
						sm="9"
						xs="12">
						<v-row
							no-gutters>
							<v-col
								cols="12"
								xl="11"
								lg="11"
								md="10"
								sm="12"
								xs="12">
								<v-row
									class="py-3">
									<v-col
										cols="12"
										xl="3"
										lg="3"
										md="6"
										sm="6"
										xs="12">
										<v-select
											prepend-inner-icon="fas fa-calendar-alt"
											v-model="selectedDate"
											:items="dateList"
											v-bind="fieldFilterProps"
										/>
									</v-col>
									<v-col
										cols="12"
										xl="3"
										lg="3"
										md="6"
										sm="6"
										xs="12"
										v-if="selectedDate === 'custom'">
										<v-menu
											max-width="350px"
											v-model="menuDateCustom"
											ref="menuDateCustom"
											transition="scale-transition"
											offset-y
											:close-on-content-click="false"
											:return-value.sync="dates">
											<template
												v-slot:activator="{ on, attrs }">
												<v-text-field
													v-model="dateRangeText"
													readonly
													outlined
													dense
													hide-details
													v-bind="attrs"
													v-on="on"
												/>
											</template>
											<v-date-picker
												width="auto"
												range
												v-model="dates"
												no-title
												color="wine">
												<v-row
													class="mx-2">
													<v-col
														cols="12"
														class="d-flex align-end">
														<v-btn
															text
															color="wine"
															@click="menuDateCustom = false">
															Cancelar
														</v-btn>
														<v-btn
															text
															color="wine"
															@click="handlerDate(dates)">
															OK
														</v-btn>
													</v-col>
												</v-row>
											</v-date-picker>
										</v-menu>
									</v-col>
									<v-col
										cols="12"
										xl="3"
										lg="3"
										md="6"
										sm="6"
										xs="12">
										<v-autocomplete
											outlined
											dense
											v-model="filterConference.subject"
											label="Tipo de arquivo"
											v-bind="autoCompleteProps"
											:items="getConferenceFileTypes"
										/>
									</v-col>
									<v-col
										cols="12"
										xl="3"
										lg="3"
										md="6"
										sm="6"
										xs="12">
										<v-autocomplete
											outlined
											dense
											v-model="filterConference.carrierId"
											label="Operadora"
											v-bind="autoCompleteCarrierProps"
											:items="carriers"
										/>
									</v-col>
									<v-col
										cols="12"
										xl="3"
										lg="3"
										md="6"
										sm="6"
										xs="12">
										<v-menu
											ref="menu"
											v-model="menu"
											:close-on-content-click="false"
											transition="scale-transition"
											offset-y
											max-width="290px"
											min-width="auto">
											<template v-slot:activator="{ on, attrs }">
												<v-text-field
													v-model="filterConference.competence"
													placeholder="Selecione"
													label="Competência"
													readonly
													outlined
													dense
													hide-details
													v-bind="textFieldProps"
													v-on="on"
													@input="handlerFilter"
												/>
											</template>
											<v-date-picker
												@click="setCompentence"
												v-model="date"
												type="month"
												locale="pt-br"
												color="wine"
												no-title
												scrollable>
												<v-spacer />
												<v-btn
													text
													@click="cleanCompetence">
													LIMPAR
												</v-btn>
												<v-btn
													text
													@click="setCompentence">
													OK
												</v-btn>
											</v-date-picker>
										</v-menu>
									</v-col>
								</v-row>
							</v-col>
							<v-col
								:class="{
									'd-flex flex-row align-center my-2': true,
									'justify-space-between': !$vuetify.breakpoint.lgAndDown,
									'justify-end': $vuetify.breakpoint.lgAndDown,
								}"
								cols="12"
								xl="1"
								lg="1"
								md="2"
								sm="12"
								xs="12">
								<v-btn
									icon
									class="wine mx-4"
									@click="handlerFilter">
									<v-img
										height="30"
										contain
										src="icons/sync.svg"
										:class="{
											syncSearchTransition: loadSearch
										}"
									/>
								</v-btn>
								<v-icon
									class="onClickEffect mr-3"
									:style="syncIconTransition"
									@click="expand"
									color="#757575">
									fas fa-chevron-up
								</v-icon>
							</v-col>
						</v-row>
					</v-col>
				</v-row>
			</v-container>
			<v-expansion-panel-content>
				<v-container
					fluid
					class="pr-0">
					<v-row
						class="wine--text">
						<v-col
							cols="12"
							xl="1"
							lg="2"
							md="3"
							sm="3"
							xs="12"
							class="d-flex justify-start pl-1">
							<div
								@click.stop="handlerClearFilters"
								style="cursor: pointer;">
								<v-icon
									class="pr-2"
									color="wine"
									size="15">
									fas fa-redo
								</v-icon>
								<span
									class="text-subtitle-2 font-weight-regular wine--text"
									style="color: #504F4F">
									Limpar filtros
								</span>
							</div>
						</v-col>
						<v-col
							cols="12"
							xl="11"
							lg="10"
							md="9"
							sm="9"
							xs="12"
							class="pr-0">
							<v-row
								no-gutters>
								<v-col
									cols="12"
									xl="11"
									lg="11"
									md="10"
									sm="12"
									xs="12">
									<v-row>
										<v-col
											cols="12"
											xl="3"
											lg="3"
											md="6"
											sm="6"
											xs="12">
											<v-autocomplete
												v-model="filterConference.financialGroupId"
												label="Grupo financeiro"
												v-bind="autoCompleteProps"
												:items="financialGroups"
											/>
										</v-col>
										<v-col
											cols="12"
											xl="3"
											lg="3"
											md="6"
											sm="6"
											xs="12">
											<v-autocomplete
												v-model="filterConference.contractId"
												label="Contrato"
												v-bind="autoCompleteProps"
												:items="contracts"
												:loading="loadingContracts"
											/>
										</v-col>
										<v-col
											cols="12"
											xl="3"
											lg="3"
											md="6"
											sm="6"
											xs="12">
											<v-autocomplete
												v-model="filterConference.subContractId"
												label="Subcontrato"
												v-bind="autoCompleteProps"
												:items="subContracts"
												:loading="loadingSubContracts"
											/>
										</v-col>
										<v-col
											cols="12"
											xl="3"
											lg="3"
											md="6"
											sm="6"
											xs="12">
											<v-text-field
												v-model="filterConference.fileId"
												label="Nº Protocolo"
												v-bind="textFieldProps"
											/>
										</v-col>
									</v-row>
								</v-col>
								<v-col
									cols="12"
									xl="1"
									lg="1"
									md="2"
									sm="12"
									xs="12"
								/>
							</v-row>
						</v-col>
						<v-col
							cols="1"
						/>
					</v-row>
				</v-container>
			</v-expansion-panel-content>
		</v-expansion-panel>
	</v-expansion-panels>
</template>

<script>
import FavoriteSearchComponent from '@/components/FavoriteSearchFilters/FavoriteSearchComponent.vue';
import OperationalRoutinesMixin from '@/shared/mixins/operationalRoutines/operationalRoutinesMixin';
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';
import InsuranceCarriersMixin from '@/shared/mixins/sdi/insuranceCarriersMixin';
import DataLoadService from '@/services-http/sdi/DataLoadService';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import moment from 'moment';
import lodash from 'lodash';

export default {
	name: "ConferenceFilter",

	components: {
		FavoriteSearchComponent
	},

	data: () => ({
		date: null,
    filterExpanded: null,
		menuDateCustom: null,
		dateTypes: [],
		selectedDate: null,
		dateType: ['createdAt'],
		dateList: [
			{
				text: 'Últimos 7 dias',
				value: 'lastSevenDays',
				disabled: false,
			},
			{
				text: 'Últimos 30 dias',
				value: 'lastThirtyDays',
				disabled: false,
			},
			{
				text: 'Últimos 3 meses',
				value: 'lastThreeMonths',
				disabled: false,
			},
			{
				text: 'Mês Atual',
				value: 'currentMonth',
				disabled: false,
			},
			{
				text: 'Personalizado',
				value: 'custom',
				disabled: false,
			},
			{
				text: 'Nenhuma Seleção de Data',
				value: 'noDate',
				disabled: false,
			},
			{
				text: 'Personalizado',
				value: 'custom',
				disabled: false,
			},
		],
    dateTypes: [],
    selectedDate: 'lastThirtyDays',
    dateType: ['createdAt'],
    dateList: [
      {
        text: 'Últimos 7 dias',
        value: 'lastSevenDays',
        disabled: false,
      },
      {
        text: 'Últimos 30 dias',
        value: 'lastThirtyDays',
        disabled: false,
      },
      {
        text: 'Últimos 3 meses',
        value: 'lastThreeMonths',
        disabled: false,
      },
      {
        text: 'Mês Atual',
        value: 'currentMonth',
        disabled: false,
      },
      {
        text: 'Nenhuma Seleção de Data',
        value: 'noDate',
        disabled: false,
      },
      {
        text: 'Personalizado',
        value: 'custom',
        disabled: false,
      },
    ],
		justFilesToReprocess: false,
		overlay: false,
		menu: false,
		date: null,
    changeTabValue: 'FILE_DATA',
		fieldFilterProps: {
			outlined: true,
			dense: true,
			hideDetails: true,
			clearable: false,
			color: "wine",
			itemColor: "wine",
			placeholder: "Filtrar data",
		},
		autoCompleteProps: {
			placeholder: "Selecione",
			hideDetails: true,
			itemText: "name",
			itemValue: "id",
			outlined: true,
			clearable: true,
			dense: true,
			color: "wine--text",
			itemColor: "wine--text",
		},
		autoCompleteCarrierProps: {
			placeholder: "Selecione",
			itemText: "carrierName",
			hideDetails: true,
			itemValue: "carrierId",
			outlined: true,
			clearable: true,
			dense: true,
			color: "wine--text",
			itemColor: "wine--text",
		},
		textFieldProps: {
			outlined: true,
			clearable: true,
			dense: true,
			hideDetails: true,
			color: "wine--text",
			itemColor: "wine--text",
		},
		contracts: [],
		subContracts: [],
		loadingContracts: false,
    loadingSubContracts: false,
    watcherCalledGetContract: false,
    watcherCalledGetSubContract: false,
		overlay: false,
		carriers: [],
		dates: [],
		datesSearch: null,
	}),

	props: {
		financialGroups: { type: Array },
    insuranceCarriers: { type: Array },
		resetFilterConference: {
			type: Boolean,
			default: false
		},
		loadSearch: {
			type: Boolean,
			default: false
		}
	},

	mixins: [
		AuthorityManagementMixin,
		OperationalRoutinesMixin,
		InsuranceCarriersMixin,
		ContractsMixin,
	],

	watch: {
		date: {
      deep: true,
      handler(value) {
        if (value) this.setCompentence();
      },
    },
		filterConference: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value) {
					this.$emit("hasFiltersConference", this.filterConference)
					setTimeout(() => {
						this.handlerFilter();
					}, 200)
				}
			}
		},
		resetFilterConference: {
			deep: true,
			handler(value) {
				if (value) this.clearFilterConference()
			}
		},
    'filterConference.financialGroupId': {
      deep: true,
      handler(value) {
				this.filterConference.contractId = null;
				this.filterConference.subContractId = null;
        const financialGroupId = lodash.cloneDeep(value);
        if (financialGroupId) {
          if (!this.watcherCalledGetContract) {
            this.loadContractsByFinancialGroupId(financialGroupId);
          }
        } else {
          this.contracts = [];
          this.subContracts = [];
          this.watcherCalledGetContract = false;
        }
      },
    },
    'filterConference.contractId': {
      deep: true,
      handler(value) {
				this.filterConference.subContractId = null;
        if (value) {
          if (!this.watcherCalledGetSubContract) {
            this.loadSubContractsByContractId(value);
          }
        } else {
          this.subContracts = [];
          this.watcherCalledGetSubContract = false;
        }
      },
    },
		selectedDate: {
      immediate: true,
      deep: true,
      handler(newValue) {
        const dateValue = newValue || 'lastThirtyDays';
        this.setDateFilter(dateValue);
        this.checkSelectedDate();
				setTimeout(() => {
					this.handlerFilter();
				}, 200)
      },
    },
  },

	methods: {
		areAdditionalConditionsMet(
			subject, createdBy, carrierId, financialGroupId, contractId,
			subContractId, fileId, status, extProcessStatus, competence
		) {
      return (
        this.isEmpty(fileId)
        && this.isEmpty(subject)
        && this.isEmpty(createdBy)
        && this.isEmpty(carrierId)
        && this.isEmpty(financialGroupId)
        && this.isEmpty(contractId)
        && this.isEmpty(subContractId)
        && this.isEmpty(competence)
        && this.isEmpty(status)
        && this.isEmpty(extProcessStatus)
      );
    },
		areAllMandatoryFieldsEmpty(...fields) {
      return fields.every((field) => this.isEmpty(field));
    },
		areSpecificConditionsMet(
			subject, createdBy, carrierId, financialGroupId, contractId,
			subContractId, fileId, status, extProcessStatus, competence
		) {
      return (
        this.isEmpty(fileId)
        && this.isEmpty(subject)
        && this.isEmpty(createdBy)
        && this.isEmpty(carrierId)
        && this.isEmpty(financialGroupId)
        && this.isEmpty(contractId)
        && this.isEmpty(subContractId)
        && this.isEmpty(competence)
        && this.isEmpty(status)
        && this.isEmpty(extProcessStatus)
      );
    },
		cleanCompetence() {
      this.filterConference.competence = null;
			this.date = null;
    },
		clearFilterConference() {
			this.filterConference = {
				uploadType: '',
				carrierId: '',
				financialGroupId: '',
				contractId: '',
				subContractId: '',
				fileId: '',
				competence: '',
      },
			this.$forceUpdate();
			this.selectedDate = 'lastThirtyDays';
			this.$emit("resetStatusFilters", false);
		},
		checkSelectedDate() {
      if (this.selectedDate === 'noDate') {
        this.$emit('openExpandedContent', 0);
      }
    },
		expand() {
			if (this.filterExpanded === 0) {
				this.filterExpanded = undefined
			} else {
				this.filterExpanded = 0
			}
		},
		handlerClearFilters() {
      sessionStorage.removeItem('searchParams');
      this.$emit('clearFilters');
    },
		handlerDate(dates) {
			this.datesSearch = null;
      this.menuDateCustom = false;
			const dateFormated = dates;

      if (dateFormated[0] > dateFormated[1]) {
        [dateFormated[0], dateFormated[1]] = [dateFormated[1], dateFormated[0]];
      }

      this.$refs.menuDateCustom.save(dateFormated);
      this.setDataCustom(dateFormated);
			this.handlerFilter();
    },
		handlerFilter() {
      if (this.validateObject()) {
        if (this.dateType.length !== 0 || this.selectedDate === 'noDate') {
          let { dates } = this;
          if (this.selectedDate === 'noDate') {
            dates = [];
          } else {
            this.setDateFilter(this.selectedDate)
            dates = this.dates
          }
          const { dateType } = this;
          sessionStorage.removeItem('searchParams');
          this.$emit('handlerClickFilter', dates, dateType);
        } else {
          this.menu = true;
          this.$refs.SnackbarCustomize.open('error', 'Selecione um tipo de data para prosseguir');
        }
      }
    },
		isEmpty(value) {
      return value === '' || value === undefined || value === null;
    },
		loadContractsByFinancialGroupId(financialGroupId) {
      return new Promise((resolve) => {
        this.getContractsByFinancialGroupId(financialGroupId).then(() => {
          resolve();
        });
      });
    },
    loadSubContractsByContractId(contractId) {
      return new Promise((resolve) => {
        this.getSubContractsByContractId(contractId).then(() => {
          resolve();
        });
      });
    },
		loadThisFilter(data) {
      this.setOverlay(true);
      this.filterConference = {
        subject: data.queryParams.subject ? data.queryParams.subject : '',
        carrierId: data.queryParams.carrierId ? parseInt(data.queryParams.carrierId) : '',
        financialGroupId: data.queryParams.financialGroupId ? parseInt(data.queryParams.financialGroupId) : '',
        contractId: data.queryParams.contractId ? parseInt(data.queryParams.contractId) : '',
        subContractId: data.queryParams.subContractId ? parseInt(data.queryParams.subContractId) : '',
        fileId: data.queryParams.fileId ? data.queryParams.fileId : '',
				competence: data.queryParams.competence ? data.queryParams.competence : '',
      };

      this.$emit('hasFilterConference', this.filterConference);

      setTimeout(() => {
        this.setOverlay(false);
      }, 500);
    },
		setCompentence() {
			if (Boolean(this.date)) {
				this.filterConference.competence = moment(this.date, 'YYYY-MM').format('MM/YYYY');
			}
      this.menu = false;
    },
		setDataCustom(dates) {
      if (this.selectedDate === 'custom') {
        const cleanedDates = dates.map((date) => {
          if (date.includes('T00:00:00.000')) {
            date = date.replaceAll('T00:00:00.000', '');
          }
          if (date.includes('T23:59:59.999')) {
            date = date.replaceAll('T23:59:59.999', '');
          }
          return date;
        });
        const datesSearch = {
          dateType: this.dateType,
          dates: cleanedDates,
        };
				this.datesSearch = datesSearch;
      }
    },
		setDateFilter(value) {
      const today = moment();
      const dateRanges = {
        lastSevenDays: 6,
        lastThirtyDays: 29,
        lastThreeMonths: 89,
        currentMonth: today.clone().startOf('day').diff(today.clone().startOf('month').startOf('day'), 'days'),
        noDate: 'noDate',
      };

      let startDate;
      if (value === 'noDate') {
        this.dates = [];
        startDate = [];
      } else if (dateRanges[value] !== undefined) {
        startDate = today.clone().subtract(dateRanges[value], 'days');
        this.dates = [startDate.format('YYYY-MM-DD'), today.format('YYYY-MM-DD')];
      }

      sessionStorage.setItem('selectedDate', this.selectedDate);
			this.setDataCustom(this.dates);
    },
		setOverlay(value) {
      this.overlay = value;
    },
		async setMandatoryCarriers() {
			await this.dataLoadService.GetAllConfigMandatoryExtFile().then(res => {
				if (res.data.length > 0) {
					this.carriers = [];
					res.data.forEach((carrier, index) => {
						if (index === 0) {
							this.carriers.push(carrier);
						}
						if (
							carrier.carrierId != null
							&& !this.carriers.some(item => item.carrierId === carrier.carrierId)
						) {
							this.carriers.push(carrier);
						}
					});

					this.carriers.sort((a, b) => (a.carrierName > b.carrierName ? 1 : -1));
				}
			})
		},
		validateObject() {
      if (this.selectedDate === 'noDate') {
        const {
          subject, createdBy, carrierId, financialGroupId, contractId,
					subContractId, fileId, status, extProcessStatus, competence
        } = this.filtersForCheckDate;

        const showMessageAndReturnFalse = (message) => {
          this.$refs.SnackbarCustomize.open('error', message);
          return false;
        };

        if (this.areAllMandatoryFieldsEmpty(
          subject, createdBy, carrierId, financialGroupId, contractId,
					subContractId, fileId, status, extProcessStatus, competence)
        ) {
          return showMessageAndReturnFalse('Preencha pelo menos um campo para prosseguir com a pesquisa ou selecione uma data específica');
        }

        if (this.areSpecificConditionsMet(
          subject, createdBy, carrierId, financialGroupId, contractId,
					subContractId, fileId, status, extProcessStatus, competence)
        ) {
          return showMessageAndReturnFalse('Adicione um outro filtro de pesquisa para prosseguir ou selecione uma data específica');
        }

        if (this.areAdditionalConditionsMet(
          subject, createdBy, carrierId, financialGroupId, contractId,
					subContractId, fileId, status, extProcessStatus, competence)
        ) {
          return showMessageAndReturnFalse('Adicione um outro filtro de pesquisa para prosseguir ou selecione uma data específica');
        }

        return true;
      }

      return true;
    },
	},

	computed: {
		dateRangeText() {
      let text = '-';
      if (this.dates && this.dates.length > 0) {
        const sortedDates = this.dates.slice().sort((a, b) => new Date(a) - new Date(b));

        const formattedStartDate = moment(sortedDates[0]).format('DD/MM/YYYY');
        const formattedEndDate = moment(sortedDates[1]).format('DD/MM/YYYY');

        text = `${formattedStartDate} ~ ${formattedEndDate}`;
      }

      return text;
    },
		syncIconTransition() {
			return {
        transform: this.filterExpanded !== 0 ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 0.25s',
      };
		},
	},

	created() {
		this.dataLoadService = new DataLoadService();
	},

	async mounted() {
		await this.setMandatoryCarriers();
	},
}
</script>

<style scoped>
.onClickEffect {
	background-color: transparent !important;
}
.onClickEffect:after {
	background-color: transparent !important;
}

@keyframes rotate {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}

.syncSearchTransition {
	transform: 0.5s ease-out;
  animation: rotate 1.2s linear infinite;
}
</style>
