<template>
  <v-hover
    v-slot="{ hover }">
    <v-sheet
      id="ConferenceCard"
      @click="selectCard"
      height="70"
      width="100%"
      elevation="4"
      color="wine"
      :class="{
        'on-hover': hover,
        'rounded-lg': !isMulti,
        'rounded-l-lg rounded-r-0': isMulti && indexInnerCard === 0,
        'rounded-r-lg rounded-l-0': isMulti && indexInnerCard === 1,
      }">
      <v-container
        fluid
        class="fill-height pa-0">
        <v-row
          no-gutters
          class="d-flex align-content-space-between flex-wrap px-12">
          <v-col
            cols="12"
            class="d-flex flex-row align-center">
            <span
              class="white--text font-weight-regular"
              style="font-size: 2.5rem;">
              {{ value }}
            </span>
            <span
              class="white--text font-weight-medium ml-4"
              style="font-size: 1.125rem;">
              {{ label }}
            </span>
            <v-spacer />
            <v-sheet
              height="40"
              width="40"
              rounded="pill"
              v-if="icon"
              class="d-flex justify-center align-center">
              <v-icon
                size="30"
                color="wine">
                {{ icon }}
              </v-icon>
            </v-sheet>
            <v-sheet
              v-if="secondaryIcon"
              height="40"
              width="40"
              rounded="pill"
              class="d-flex justify-center align-center ml-5">
              <v-icon
                size="30"
                color="wine">
                {{ secondaryIcon }}
              </v-icon>
            </v-sheet>
          </v-col>
        </v-row>
      </v-container>
    </v-sheet>
  </v-hover>
</template>
<script>
export default {
  name: "ConferenceCard",

  props: {
    label: String,
    value: null,
    icon: null,
    secondaryIcon: null,
    isMulti: {
      type: Boolean,
      default: false,
    },
    indexInnerCard: null,
  },

  methods: {
    selectCard() {
      this.$emit('selectCard')
    },
  }
}
</script>
<style scoped>
#ConferenceCard {
  transition: opacity .4s ease-in-out;
}
#ConferenceCard:not(.on-hover) {
  opacity: 0.6;
}
</style>