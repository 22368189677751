<template>
	<div>
		<v-expansion-panels
			v-model="expanded">
			<v-expansion-panel>
				<RegisterCard
					:item="item"
					:dependents="dependents"
					:invoicesToValidate="invoicesToValidate"
					@openCriticsInvoiceModal="openCriticsInvoiceModal"
					@openConfirmationCommentModal="openConfirmationCommentModal"
					class="my-1">
					<template slot="checkbox">
						<slot name="register-checkbox"></slot>
					</template>
					<template slot="expanded">
						<div
							style="min-width: 33px;">
							<v-icon
								v-if="dependents.length > 0"
								class="onClickEffect ml-3"
								:style="syncIconTransition"
								@click="expand"
								color="#757575">
								fas fa-chevron-up
							</v-icon>
						</div>
					</template>
				</RegisterCard>
				<v-expansion-panel-content
					class="invoicePanelContent my-1"
					v-for="(dependent, dependentIndex) in dependents"
					:key="dependentIndex">
					<RegisterCard
						:item="dependent"
						@openCriticsInvoiceModal="openCriticsInvoiceModal"
						@openConfirmationCommentModal="openConfirmationCommentModal">
						<template slot="checkbox">
							<v-checkbox
								hide-details
								:value="dependent.validationDetailId"
								v-model="dependentInvoicesToValidate"
								color="wine"
								class="ma-0 pa-0"
								multiple
								v-if="!dependent.checked && dependent.status !== 'SUCCESS'"
							/>
						</template>
						<template slot="expanded">
							<div
								style="min-width: 33px;">
								<v-icon
									v-if="false"
									class="onClickEffect ml-3"
									:style="syncIconTransition"
									@click="expand"
									color="#757575">
									fas fa-chevron-up
								</v-icon>
							</div>
						</template>
					</RegisterCard>
				</v-expansion-panel-content>
			</v-expansion-panel>
		</v-expansion-panels>

		<ConfirmationCommentModal
			ref="ConfirmationCommentModal"
			@confirm="confirmValidation"
			:title="'Validar registro'"
			:description="'Descreva abaixo a razão para validar este registro.'"
		/>
		<Loader :overlay="loadingOverlay" />
		<SnackbarCustomize ref="SnackbarCustomize" />
	</div>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import OperationalRoutinesMixin from '@/shared/mixins/operationalRoutines/operationalRoutinesMixin';
import TodoListActions from '@/components/TodoList/TodoListActions.vue';
import ConfirmationCommentModal from '../Modals/ConfirmationCommentModal/ConfirmationCommentModal.vue';
import OperationalService from '@/services-http/operational/OperationalService';
import Loader from '@/components/Loader/Loader.vue'
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import operationalRoutinesModule from '@/store/modules/operational-routines/operational-routines-module';
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';
import RegisterCard from './RegisterCard.vue'

export default {
	name: "RegistersTable",

	components: {
		TodoListActions,
		ConfirmationCommentModal,
		Loader,
		SnackbarCustomize,
		RegisterCard,
	},

	data: () => ({
		formatter: null,
		operationalService: null,
		loadingOverlay: false,
		expanded: null,

		defaultColumnProps: {
			class: "d-flex justify-start align-center"
		},
		defaultTitleProps: {
			class: "wineTitle--text text-body-2 font-weight-regular text-truncate",
		},
		defaultSpanProps: {
			class: "wineTitle--text text-body-2 font-weight-regular text-truncate",
		},
		defaultSpanWhiteProps: {
			class: "white--text text-body-2 font-weight-regular text-truncate",
		},
		mainSpanProps: {
			class: "wineLabel--text text-body-2 font-weight-regular text-truncate",
		},
		defaultTypeProps: {
			class: "wine--text text-h6 font-weight-medium text-truncate",
		},
		defaultDependentTypeProps: {
			class: "white--text text-h6 font-weight-medium text-truncate",
		},
		defaultTypeValueProps: {
			class: "wineLabel--text text-subtitle-1 font-weight-medium text-truncate",
		},
		dependentInvoicesToValidate: [],
	}),

	watch: {
		invoicesToValidate: {
      handler(val) {
        if (val) {
					if (this.dependents.some(item => val.includes(item.validationDetailId))) {
						this.dependentInvoicesToValidate = this.dependents
																								.filter(item => val.includes(item.validationDetailId))
																								.map(el => el.validationDetailId)
					}  else {
						this.dependentInvoicesToValidate = [];
					}
        }
      },
    },
		dependentInvoicesToValidate: {
      handler(newIds, oldIds) {
				if (this.dependents.length === 0) return;
				if (JSON.stringify(newIds) === JSON.stringify(oldIds)) return;

				const diffNewValue = newIds.length > 0 ? newIds.filter(val => !oldIds.includes(val)) : [];
				const diffOldValue = oldIds.length > 0 ? oldIds.filter(val => !newIds.includes(val)) : [];
				const removeIds = diffOldValue.length > 0 ? [...diffNewValue, ...diffOldValue] : [];

				this.$emit('dependentsToRemove', {
					addIds: newIds,
					removeIds: removeIds,
				})
      },
    }
	},

	props: {
		item: null,
		dependents: {
      type: Array,
      required: false,
			default: () => []
    },
		invoicesToValidate: {
      type: Array,
      required: false,
			default: () => []
    },
	},

	mixins: [
		OperationalRoutinesMixin,
		AuthorityManagementMixin,
	],

	methods: {
		expand() {
			if (this.expanded === 0) {
				this.expanded = undefined
			} else {
				this.expanded = 0
			}
		},
		openCriticsInvoiceModal(validationDetailId) {
			this.$emit("openCriticsInvoiceModal", validationDetailId);
		},
		openConfirmationCommentModal(validationDetailId) {
			this.$refs.ConfirmationCommentModal.open(validationDetailId);
		},
		async confirmValidation(obj) {
			this.loadingOverlay = true;
			const formData = new FormData();
      formData.append('validationDetailIds', [ obj.dataId ]);
      formData.append('comment', obj.comment);
      formData.append('checked', true);
			await this.operationalService.CheckValidationDetail(formData).then(() => {
				this.$refs.SnackbarCustomize.open('success', 'Registro validado com sucesso.');
				operationalRoutinesModule.updateInvoiceValidationDetail(obj.dataId);
				this.$emit('updateSearch')
      }).catch(() => {
				this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao tentar validar o registro.');
      }).finally(() => {
        this.loadingOverlay = false;
      });
		},
	},

	computed: {
		syncIconTransition() {
			return {
        transform: this.expanded !== 0 ? 'rotate(180deg)' : 'rotate(0deg)',
        transition: 'transform 0.25s',
      };
		},
	},

	created() {
		this.formatter = new Formatters();
		this.operationalService = new OperationalService();
	},
}
</script>

<style>
.expand-enter,
.expand-leave {
	transition: width .7s ease;
}

.expand-text-enter {
	transition: width .7s ease;
	transition: opacity .7s ease;
	opacity: 1;
}

.expand-text-leave {
	transition: width .7s ease;
	transition: opacity .7s ease;
	transition: display 1s ease;
	display: 0;
	opacity: 0;
}

.invoicePanelContent > .v-expansion-panel-content__wrap {
	padding: 0 !important;
}
</style>

<style scoped>
.syncSearchTransition {
	transform: 0.5s ease-out;
  animation: rotate 1.2s linear infinite;
}
</style>