<template>
	<v-row>
		<v-col
			cols="12"
			class="py-0">
			<p
				class="wineLabel--text pb-0 mb-0"
				style="font-size: 1.125rem;">
				Selecione o contrato desejado:
			</p>
			<v-simple-table
				class="overflow-auto"
				style="
					max-height: 215px;
					border: 1px solid rgba(0, 0, 0, .2);">
				<thead
					class="wineSecondary">
					<tr>
						<th
							v-for="header in headers"
							:key="header.text"
							class="text-left white--text text-subtitle-1 font-weight-medium"
							:style="header.style">
							{{ header.text }}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(item, key) in getInvoiceValidations"
						:key="key"
						@click="selectInvoice(item)"
						:class="{
							'text-subtitle-1 wineLabel--text font-weight-regular': true
						}"
						:style="contractInSelection(item) ? 'background: #EDE5EB' : ''">
						<td
							style="width: 320px">
							{{ item.validationId }}
						</td>
						<td
							style="width: 320px">
							{{ item.financialGroup.name }}
						</td>
						<td
							style="width: auto">
							{{ item.name }}
						</td>
						<td
							class="d-flex justify-end">
							<v-icon
								color="wine"
								v-if="contractInSelection(item)">
								fas fa-check
							</v-icon>
						</td>
					</tr>
				</tbody>
			</v-simple-table>
		</v-col>
		<v-col
			class="d-flex justify-end pb-0">
			<v-btn
				class="white--text rounded-pill"
				height="39px"
				width="100%"
				max-width="350"
				color="wine"
				@click="showDetails">
				Detalhes
			</v-btn>
		</v-col>
		<template
			v-if="selectedContracts.length > 0">
			<v-col
				cols="12"
				class="py-0 mt-5">
				<span
					class="wineLabel--text font-weight-bold text-h6">
					Conferência de fatura
				</span>
			</v-col>
			<v-col
				v-for="(item, registerKey) in
				[
					{
						title: 'Total de registros',
						cards: [
							{
								label: 'Validados com sucesso',
								value: allSuccessRegistryDetailIds,
								icon: 'check',
								type: 'register',
								status: 'SUCCESS',
								isInvoice: true,
								key: 'successRegistryDetailIds'
							},
							{
								label: 'Validados com erro',
								value: allErrorRegistryDetailIds,
								icon: 'mdi mdi-exclamation',
								type: 'register',
								status: 'ERROR',
								isInvoice: true,
								key: 'errorRegistryDetailIds'
							},
							{
								label: 'Com erro - conferido',
								value: allErrorRegistryDetailIdsChecked,
								icon: 'mdi mdi-exclamation',
								secondaryIcon: 'check',
								type: 'register',
								status: 'ERROR',
								isInvoice: true,
								key: 'errorRegistryDetailIdsChecked'
							},
						]
					},
					{
						title: 'Total de lançamentos de valores',
						cards: [
							{
								label: 'Validados com sucesso',
								value: allSuccessValueDetailIds,
								icon: 'check',
								type: 'value',
								status: 'SUCCESS',
								isInvoice: false,
								key: 'successValueDetailIds'
							},
							{
								label: 'Validados com erro',
								value: allErrorValueDetailIds,
								icon: 'mdi mdi-exclamation',
								tpye: 'value',
								status: 'ERROR',
								isInvoice: false,
								key: 'errorValueDetailIds'
							},
							{
								label: 'Com erro - conferido',
								value: allErrorValueDetailIdsChecked,
								icon: 'mdi mdi-exclamation',
								secondaryIcon: 'check',
								type: 'value',
								status: 'ERROR',
								isInvoice: true,
								key: 'errorValueDetailIdsChecked'
							},
						]
					},
					{
						title: 'Total de registros não localizados',
						cards: [
							{
								multi: [
									{
										label: 'Fatura',
										value: allNotFoundInvoiceDetailIds,
										icon: 'search',
										type: 'register',
										status: 'NOT_FOUND',
										isInvoice: true,
										key: 'notFoundInvoiceDetailIds',
									},
									{
										label: 'Prévia',
										value: allNotFoundPreviewDetailIds,
										icon: 'search',
										type: 'value',
										status: 'NOT_FOUND',
										isInvoice: false,
										key: 'notFoundPreviewDetailIds',
									},
								]
							},
							{
								label: 'Fatura - conferido',
								value: allNotFoundInvoiceDetailIdsChecked,
								icon: 'mdi mdi-exclamation',
								secondaryIcon: 'search',
								type: 'value',
								status: 'NOT_FOUND',
								isInvoice: false,
								key: 'notFoundInvoiceDetailIdsChecked',
							},
							{
								label: 'Prévia - conferido',
								value: allNotFoundPreviewDetailIdsChecked,
								icon: 'mdi mdi-exclamation',
								secondaryIcon: 'search',
								type: 'register',
								status: 'NOT_FOUND',
								isInvoice: true,
								key: 'notFoundPreviewDetailIdsChecked'
							},
						]
					}
				]"
				:key="'register' + registerKey"
				class="pt-0">
				<v-col
					class="d-flex flex-column pa-0">
					<span
						class="wineSecondary--text font-weight-bold text-subtitle-1">
						{{ item.title }}
					</span>
					<template
						v-for="(card, indexCard) in item.cards">
						<div
							v-if="card.multi">
							<v-sheet>
								<v-row
									no-gutters>
									<template
										v-for="(innerCard, indexInnerCard) in card.multi">
										<v-col>
											<ConferenceCard
												:class="indexInnerCard <= 1 ? 'mb-6' : ''"
												:label="innerCard.label"
												:value="innerCard.value"
												:indexInnerCard="indexInnerCard"
												:isMulti="true"
												style="cursor: pointer;"
												@selectCard="selectCard(registerKey, 'register', innerCard)"
											/>
										</v-col>
									</template>
								</v-row>
							</v-sheet>
						</div>
						<div
							v-else>
							<ConferenceCard
								:class="indexCard <= 1 ? 'mb-6' : ''"
								:label="card.label"
								:value="card.value"
								:icon="card.icon"
								:secondaryIcon="card.secondaryIcon"
								style="cursor: pointer;"
								@selectCard="selectCard(registerKey, 'register', card)"
							/>
						</div>
					</template>
				</v-col>
			</v-col>
		</template>
		<SnackbarCustomize ref="SnackbarCustomize" />
	</v-row>
</template>

<script>
import operationalRoutinesModule from '@/store/modules/operational-routines/operational-routines-module';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import ConferenceCard from './ConferenceCard.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
	name: "InvoiceConference",

	components: {
		SnackbarCustomize,
		ConferenceCard,
	},

	data: () => ({
		headers: [
			{
				text: 'Protocolo',
				value: 'financialGroup',
				style: 'width: 320px'
			},
			{
				text: 'Grupo Financeiro',
				value: 'financialGroup',
				style: 'width: 320px'
			},
			{
				text: 'Contrato',
				value: 'contract',
				style: 'width: auto'
			},
			{
				text: '',
				value: '',
				style: 'width: auto'
			},
		],
	}),

	methods: {
		...mapMutations({
			addContract: 'operational-routines/addContract',
			removeContract: 'operational-routines/removeContract',
		}),
		selectInvoice(invoice) {
			if (this.selectedContracts.some(el => el.validationId === invoice.validationId)) {
				this.removeContract(invoice);
				return;
			}

			this.addContract(invoice);
		},
		showDetails() {
			this.$emit('showDetailsTab')
		},
		contractInSelection(contract) {
			return this.selectedContracts.some(el => el.validationId === contract.validationId)
		},
		selectCard(registerKey, type, card) {
			switch (registerKey) {
				case 0:
					this.$emit('selectCard', {
						type: type,
						card: card,
						selectedContracts: this.selectedContracts,
						status: card.status,
						isInvoice: card.isInvoice,
						key: card.key,
						ids: this.getAllRegistryIds(card.key),
					})
					break;
				case 1:
					this.$emit('selectCard', {
						type: type,
						card: card,
						selectedContracts: this.selectedContracts,
						status: card.status,
						isInvoice: card.isInvoice,
						key: card.key,
						ids: this.getAllRegistryIds(card.key),
					})
					break;
				case 2:
					this.$emit('selectCard', {
						type: type,
						card: card,
						selectedContracts: this.selectedContracts,
						status: card.status,
						isInvoice: card.isInvoice,
						key: card.key,
						ids: this.getAllRegistryIds(card.key),
					})
					break;
			}
		},
		mergeLists(currentList, newList) {
			return [ ...currentList, ...newList ];
		},
		reduceList(key) {
			return this.selectedContracts.reduce((currentList, item) => {
				return this.mergeLists(currentList, item[key])
			}, []);
		},
		getAllRegistryIds(key) {
			return this.reduceList(key);
		},
	},

	computed: {
		...mapGetters({
			getInvoiceValidations: 'operational-routines/invoiceValidations',
			selectedContracts: 'operational-routines/selectedContracts'
		}),
		allSuccessRegistryDetailIds() {
			return this.reduceList('successRegistryDetailIds').length;
		},
		allErrorRegistryDetailIds() {
			return this.reduceList('errorRegistryDetailIds').length;
		},
		allErrorRegistryDetailIds() {
			return this.reduceList('errorRegistryDetailIds').length;
		},
		allErrorRegistryDetailIdsChecked() {
			return this.reduceList('errorRegistryDetailIdsChecked').length;
		},
		allErrorRegistryDetailIdsChecked() {
			return this.reduceList('errorRegistryDetailIdsChecked').length;
		},
		allSuccessValueDetailIds() {
			return this.reduceList('successValueDetailIds').length;
		},
		allErrorValueDetailIds() {
			return this.reduceList('errorValueDetailIds').length;
		},
		allErrorValueDetailIdsChecked() {
			return this.reduceList('errorValueDetailIdsChecked').length;
		},
		allNotFoundInvoiceDetailIds() {
			return this.reduceList('notFoundInvoiceDetailIds').length;
		},
		allNotFoundPreviewDetailIds() {
			return this.reduceList('notFoundPreviewDetailIds').length;
		},
		allNotFoundInvoiceDetailIdsChecked() {
			return this.reduceList('notFoundInvoiceDetailIdsChecked').length;
		},
		allNotFoundPreviewDetailIdsChecked() {
			return this.reduceList('notFoundPreviewDetailIdsChecked').length;
		}
	}
}
</script>
