<template>
	<v-container
		fluid
		class="pb-0"
		style="overflow-x: auto; overflow-y: hidden;">
		<v-row
			style="min-width: 1400px; ">
			<v-col
				cols="12"
				class="mt-2 pt-0 d-flex flex-row align-end">
				<span
					class="wineLabel--text"
					style="font-size: 1.125rem;">
					Detalhes das faturas
				</span>
				<v-spacer />
				<!-- <v-btn
					height="50"
					width="263"
					class="wine white--text rounded-pill text-h6"
					:loading="loadingExport"
					@click="exportInvoice">
					Exportar
				</v-btn> -->
			</v-col>
			<v-col
				cols="12"
				class="py-0">
				<v-data-table
					id="invocieDetailsDataTable"
					class="elevation-0 transparent"
					style="min-width: 1500px;"
					item-key="id"
					:items="invoiceByContractsSelected"
					:loading="loading"
					:items-per-page="itemsPerPage"
					:page="page"
					:custon-sort="{
						isDescending: true
					}"
					:footer-props="{
						itemsPerPageOptions: [10, 25, 50, 75, 100],
						disablePagination: loading
					}"
          hide-default-header
				>
					<template v-slot:no-data>
						<span>Nenhum dado encontrado.</span>
					</template>
					<template v-slot:header>
						<v-container
							fluid>
							<v-row>
								<ValuesHeader />
							</v-row>
						</v-container>
					</template>
					<template v-slot:item="{ item }">
						<ValuesTable
							:item="item"
							@openInvoiceModal="openInvoiceModal"
							@showInvoiceDetails="$emit('showInvoiceDetails', $event, {})"
						/>
					</template>
				</v-data-table>
			</v-col>
			<v-col>
				<div
					v-if="invoiceByContractsSelected.length > 0"
					class="d-flex flex-row"
					style="min-width: 1500px;">
					<v-expand-x-transition>
						<v-alert
							id="invoiceDetailsAlert"
							border="left"
							colored-border
							color="#2E74B6"
							type="info"
							:style="
								showAlert
								? 'background-color: #69ADED; max-height: 53px; width: 70%;'
								: 'background-color: #69ADED; max-height: 53px; width: 0%;'"
							:class="{
								'rounded-lg my-0': true,
							}"
							v-show="showAlert">
							<v-row
								class="d-flex justify-space-between">
								<span
									class="white--text">
									Os contratos selecionados estão vinculados a faturas diferentes.
								</span>
								<v-icon
									v-if="showAlert"
									color="white"
									@click="showAlert = false">
									close
								</v-icon>
							</v-row>
						</v-alert>
					</v-expand-x-transition>
					<v-expand-x-transition>
						<v-sheet
							color="wineSecondary"
							:class="{
								'rounded-lg white--text py-2 px-4 d-flex align-center': true,
								'ml-2 expand-enter overflow-hidden': showAlert,
								'expand-leave': !showAlert,
							}"
							height="53"
							:style="
								showAlert
								? 'width: 100%; max-width: 30%;'
								: 'width: 100%;'">
							<v-row
								no-gutters
								class="grid-operational-routines-container">
								<v-col
									:class="{
										'grid-column-3': !showAlert
									}"
									style="min-width: 460px; max-width: 460px;">
									<span>
										Total de faturas selecionadas: {{ invoiceByContractsSelected.length }}
									</span>
								</v-col>
								<v-col
									v-if="!showAlert">
									<span>
										{{ allInvoiceTotalLives }}
									</span>
								</v-col>
								<v-col
									v-if="!showAlert"
									class="pl-1 grid-column-2">
									<span>
										{{ allInvoicePremium }}
									</span>
								</v-col>
								<v-col
									v-if="!showAlert"
									class="pl-4 grid-column-2">
									<span>
										{{ allInvoiceIOF }}
									</span>
								</v-col>
								<v-col
									v-if="!showAlert"
									class="pl-8 grid-column-2">
									<span>
										{{ allInvoiceOutherValue }}
									</span>
								</v-col>
								<v-col
									v-if="!showAlert"
									class="pl-10 grid-column-2">
									<span>
										{{ allInvoiceRetroactivity }}
									</span>
								</v-col>
								<v-col
									v-if="!showAlert"
									class="pl-14 grid-column-2">
									<span>
										{{ allInvoiceTotalValue }}
									</span>
								</v-col>
							</v-row>
						</v-sheet>
					</v-expand-x-transition>
				</div>
			</v-col>
		</v-row>

		<SnackbarCustomize ref="SnackbarCustomize" />
		<CriticsInvoiceModal ref="CriticsInvoiceModal" :hasComment="false" />
		<InvoiceModal ref="InvoiceModal" :hasComment="true" :filter="filter" />
	</v-container>
</template>

<script>
import CriticsInvoiceModal from '../Modals/CriticsInvoiceModal/CriticsInvoiceModal.vue';
import SnackbarCustomize from "@/components/CustomAlerts/SnackbarCustomize.vue";
import InvoiceModal from '../Modals/InvoiceModal/InvoiceModal.vue';
import ValuesHeader from "./ValuesHeader.vue";
import ValuesTable from "./ValuesTable.vue";

import OperationalRoutinesMixin from "@/shared/mixins/operationalRoutines/operationalRoutinesMixin";
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import Formatters from '@/shared/formatters/formatters';

import OperationalService from "@/services-http/operational/OperationalService.js";
import { mapGetters } from 'vuex';

export default {
	name: "InvoiceDetailsTable",

	components: {
		CriticsInvoiceModal,
		SnackbarCustomize,
		InvoiceModal,
		ValuesHeader,
		ValuesTable,
	},

	data: () => ({
		loadingExport: false,
		loading: false,
		showAlert: false,
		formatter: null,

		operationalService: null,

		operationalRoutines: [],

		itemsPerPage: 10,
		page: 1,
	}),

	mixins: [
		OperationalRoutinesMixin,
		FinancialGroupsMixin,
		VerifyRoutesMixin,
		ContractsMixin,
	],

	props: {
		filter: null
	},

	watch: {
		filterOperational: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value) this.$emit("hasFiltersOperational", this.filterOperational)
			}
		},
		invoiceByContractsSelected: {
			deep: true,
			immediate: true,
			handler(contracts) {
				if (contracts && contracts.length > 1) {
					const contractLength = this.numberOfDifferentContracts(contracts);
					if (contractLength > 1) {
						this.showAlert = true;
					} else {
						this.showAlert = false;
					}
				} else {
					this.showAlert = false;
				}
			}
		}
	},

	methods: {
		openInvoiceModal(invoice) {
			this.$refs.InvoiceModal.open(invoice);
		},
		setValidationContract(invoice) {
			if (this.selectedContracts.length > 0) {
				return this.selectedContracts.some(el => el.validationId === invoice.validationId);
			}
			return Object.entries(invoice).length > 0 === true;
		},
		numberOfDifferentContracts(contracts) {
			const differentContracts = contracts.filter((item, index, self) =>
				index === self.findIndex((t) => t.policy === item.policy)
			);
			return differentContracts.length;
		}
	},

	computed: {
		...mapGetters({
			carrierInvoicePriceValidation: 'operational-routines/invoicePriceValidations',
			selectedContracts: 'operational-routines/selectedContracts',
		}),
		invoiceByContractsSelected() {
			return this.carrierInvoicePriceValidation.filter(invoice => this.setValidationContract(invoice))
		},
		allInvoiceTotalLives() {
			return this.invoiceByContractsSelected.reduce((total, el) => {
				return total + el.invoiceTotalLives;
			}, 0)
		},
		allInvoicePremium() {
			const total = this.invoiceByContractsSelected.reduce((total, el) => {
				return total + parseFloat(this.formatter.calculePremium(el.invoiceValues))
			}, 0)
			return this.formatter.formatCurrency(total);
		},
		allInvoiceIOF() {
			const total = this.invoiceByContractsSelected.reduce((total, el) => {
				return total + parseFloat(el.invoiceValues.IOF)
			}, 0)
			return this.formatter.formatCurrency(total);
		},
		allInvoiceOutherValue() {
			const total = this.invoiceByContractsSelected.reduce((total, el) => {
				return total + parseFloat(this.formatter.calculeOutherValue(el.invoiceValues))
			}, 0)

			return this.formatter.formatCurrency(total);
		},
		allInvoiceRetroactivity() {
			const total = this.invoiceByContractsSelected.reduce((total, el) => {
				return total + parseFloat(this.formatter.calculeRetroactivity(el.invoiceValues))
			}, 0)
			return this.formatter.formatCurrency(total);
		},
		allInvoiceTotalValue() {
			const total = this.invoiceByContractsSelected.reduce((total, el) => {
				return total + parseFloat(el.invoiceTotalValue)
			}, 0)
			return this.formatter.formatCurrency(total);
		},
	},

	created() {
		this.operationalService = new OperationalService();
		this.formatter = new Formatters();
	},
}
</script>

<style>
#invoiceDetailsAlert > div > div.v-alert__border.v-alert__border--left.v-alert__border--has-color {
	margin: 10px 0 !important;
	max-width: 5px !important;
	border-radius: unset;
	border-width: 3px !important;
}

.expand-enter {
	transition: width .7s ease;
}
.expand-leave {
	transition: width .7s ease;
}

#invocieDetailsDataTable > div.v-data-table__wrapper {
	overflow: hidden !important;
}
</style>