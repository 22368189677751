<template>
  <v-sheet
    height="84px"
    width="100%"
    color="wineSecondary"
    rounded="lg"
    class="d-flex align-center rounded-lg white--text text-subtitle-1">
    <v-row
      no-gutters
      class="d-flex align-center">
      <v-col
        cols="1"
        class="pl-4">
        <span>
          Tipo
        </span>
      </v-col>
      <v-col
        cols="3">
        <v-row
          no-gutters>
          <v-col
            cols="12">
            <span>
              Nome
            </span>
          </v-col>
          <v-col
            cols="12">
            <span>
              Cpf/Cnpj
            </span>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="3">
        <v-row
          no-gutters>
          <v-col
            cols="12">
            Cod. Plano
          </v-col>
          <v-col
            cols="12">
            Descrição
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="1"
        class="pl-5">
        Valor
      </v-col>
      <v-col
        cols="1">
        Beneficiário
      </v-col>
      <v-col
        cols="1">
        <span>
          Status
        </span>
      </v-col>
      <v-col
        cols="1">
        <span>
          Conferido
        </span>
      </v-col>
      <v-col
        cols="1">
        <span
          class="pl-5">
          Ações
        </span>
      </v-col>
    </v-row>
  </v-sheet>
</template>
<script>
export default {
  name: "RegistersHeader"
}
</script>
