<template>
  <v-dialog
    max-width="790"
    v-model="modal">
    <template v-slot:default="dialog">
      <v-card
        class="rounded-lg">
        <v-toolbar
          width="100%"
          color="wine"
          height="79"
          style="position: relative;">
          <v-row
            class="text-center d-flex justify-center">
            <span
              class="text-h6 white--text font-weight-bold">
              Outros valores
            </span>
            <v-btn
              icon
              class="white elevation-4"
              color="wine"
              heiht="43"
              max-height="43"
              width="43"
              style="position: absolute; right: 5%; top: 20%;"
              @click="close">
              <v-icon
                size="30">
                close
              </v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
        <v-card-text
          style="height: 630px;"
          class="mt-2">
          <span
            class="wine--text text-h6 font-weight-regular pl-2">
            Insira os valores desejados:
          </span>
          <v-container
            fuild
            v-if="Object.entries(invoice).length > 0"
            class="mt-2 pt-2"
            style="max-height: 600px; overflow-y: auto;">
            <v-row
              no-gutters>
              <v-col
                cols="6">
                <span
                  :class="defaultLabelClass">
                  ISS
                </span>
              </v-col>
              <v-col
                cols="6"
                :class="defaultColumnValueClass">
                <v-text-field
                  label="Valor"
                  v-bind="defaultTextFieldProps"
                  v-model="invoice.previewValues.ISS"
                  prefix="R$"
                  v-money="money"
                  @blur="$event.preventDefault()"
                />
              </v-col>
              <v-col
                cols="6">
                <span
                  :class="defaultLabelClass">
                  IOF
                </span>
              </v-col>
              <v-col
                cols="6"
                :class="defaultColumnValueClass">
                <v-text-field
                  label="Valor"
                  v-bind="defaultTextFieldProps"
                  v-model="invoice.previewValues.IOF"
                  prefix="R$"
                  v-money="money"
                  @blur="$event.preventDefault()"
                />
              </v-col>
              <v-col
                cols="6">
                <span
                  :class="defaultLabelClass">
                  Coparticipação
                </span>
              </v-col>
              <v-col
                cols="6"
                :class="defaultColumnValueClass">
                <v-text-field
                  label="Valor"
                  v-bind="defaultTextFieldProps"
                  v-model="invoice.previewValues.RS"
                  prefix="R$"
                  v-money="money"
                  @blur="$event.preventDefault()"
                />
              </v-col>
              <v-col
                cols="6">
                <span
                  :class="defaultLabelClass">
                  2ª via de cartão
                </span>
              </v-col>
              <v-col
                cols="6"
                :class="defaultColumnValueClass">
                <v-text-field
                  label="Valor"
                  v-bind="defaultTextFieldProps"
                  v-model="invoice.previewValues.TX2"
                  prefix="R$"
                  v-money="money"
                  @blur="$event.preventDefault()"
                />
              </v-col>
              <v-col
                cols="6">
                <span
                  :class="defaultLabelClass">
                  Acertos
                </span>
              </v-col>
              <v-col
                cols="6"
                :class="defaultColumnValueClass">
                <v-text-field
                  label="Valor"
                  v-bind="defaultTextFieldProps"
                  v-model="hits"
                  prefix="R$"
                  v-money="money"
                />
              </v-col>
              <v-col
                cols="6">
                <span
                  :class="defaultLabelClass">
                  Outros Valores
                </span>
              </v-col>
              <v-col
                cols="6"
                :class="defaultColumnValueClass">
                <v-text-field
                  label="Valor"
                  v-bind="defaultTextFieldProps"
                  v-model="invoice.previewValues.OUT"
                  prefix="R$"
                  v-money="money"
                />
              </v-col>
            </v-row>
            <v-row
              v-if="hasComment"
              class="mt-0">
              <v-col
                cols="12">
                <v-textarea
                  placeholder="Digite aqui..."
                  id="textAreaInvoiceModal"
                  v-model="comment"
                  outlined
                  hide-details>
                  <template
                    v-slot:label>
                    <span
                      class="wineTitle--text text-subtitle-2">
                      Comentário
                    </span>
                  </template>
                </v-textarea>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-center">
                <v-btn
                  height="50"
                  width="260"
                  color="wine"
                  class="rounded-pill"
                  @click="updatePreviewTotal"
                  :loading="loading">
                  <span
                    class="white--text text-subtitle-1">
                    Salvar
                  </span>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import Formatters from '@/shared/formatters/formatters';
import OperationalService from '@/services-http/operational/OperationalService';
import operationalRoutinesModule from '@/store/modules/operational-routines/operational-routines-module';
import OperationalRoutinesMixin from '@/shared/mixins/operationalRoutines/operationalRoutinesMixin';
import { VMoney } from 'v-money';
import { cloneDeep } from 'lodash';

export default {
  name: "InvoiceModal",

  directives: { money: VMoney },

  data: () => ({
    modal: false,
    formatter: null,
    money: {
      decimal: ',',
      thousands: '.',
      precision: 2,
      masked: false,
    },
    hits: null,
    defaultLabelClass: "text-bidy-2 wineLabel--text font-weight-regular",
    defaultColumnValueClass: "d-flex justify-end",
    defaultTextFieldProps: {
      class: "wineLabel--text",
      outlined: true,
      dense: true,
      style: "max-width: 180px;",
    },
    operationalService: null,
    invoice: {},
    comment: '',
    loading: false,
  }),

  props: {
    hasComment: {
      type: Boolean,
      default: false,
    },
    filter: null,
  },

  mixins: [
    OperationalRoutinesMixin,
  ],

  methods: {
    open(invoice) {
      this.setInvoice(invoice);
      this.invoice = cloneDeep(invoice);
      this.modal = true;
    },
    close() {
      this.invoice = {};
      this.modal = false;
    },
    setInvoice(invoice) {
      if (Object.entries(invoice.previewValues).length > 0) {
        Object.keys(invoice.previewValues).map((key) => {
          invoice.previewValues[key] = parseFloat(invoice.previewValues[key]).toFixed(2);
        })
			  if ('AC' in invoice.previewValues && invoice.previewValues.AC > 0) {
					this.hits = invoice.previewValues.AC;
				} else if ('AD' in invoice.previewValues && invoice.previewValues.AD < 0) {
					this.hits = invoice.previewValues.AD;
				}
			}
    },
    async updatePreviewTotal() {
      let hitsValue = this.formatter.convertCurrencyStringToFloat(this.hits);
      let body = {
        validationId: this.invoice.validationId,
        totalValues: {
          ISS: this.formatter.convertCurrencyStringToFloat(this.invoice.previewValues.ISS),
          IOF: this.formatter.convertCurrencyStringToFloat(this.invoice.previewValues.IOF),
          RS: this.formatter.convertCurrencyStringToFloat(this.invoice.previewValues.RS),
          TX2: this.formatter.convertCurrencyStringToFloat(this.invoice.previewValues.TX2),
          OUT: this.formatter.convertCurrencyStringToFloat(this.invoice.previewValues.OUT),
        },
        comment: this.comment
      }
      if (hitsValue >= 0) {
        body.totalValues.AC = hitsValue;
        this.invoice.previewValues.AC = hitsValue;
        body.totalValues.AD = this.formatter.convertCurrencyStringToFloat('0');
      } else {
        body.totalValues.AD = hitsValue;
        this.invoice.previewValues.AD = hitsValue;
        body.totalValues.AC = this.formatter.convertCurrencyStringToFloat('0');
      }
      this.loading = true;
      await this.operationalService.UpdatePreviewTotals(body).then(async (response) => {
        if (response.status === 200) {
          operationalRoutinesModule.updateInvoice(this.invoice);
          await this.getCarrierInvoces();
          this.close();
        }
      }).catch(() => {
      }).finally(() => {
        this.loading = false;
      });
    },
    async getCarrierInvoces() {
      const params = new URLSearchParams(this.filter);
      await Promise.all([
        this.operationalService.SearchCarrierInvoiceValidation(params),
        this.operationalService.SearchCarrierInvoicePriceValidation(params),
      ]);
    },
  },

  created() {
    this.formatter = new Formatters();
    this.operationalService = new OperationalService();
  }
}
</script>

<style>
label[for="textAreaInvoiceModal"] {
  height: 110px !important;
}
</style>