<template>
  <v-sheet
    height="78"
    class="ma-2 elevation-3 rounded-lg fill-height">
    <v-row
      no-gutters
      class="fill-height grid-operational-routines-container px-2 overflow-hidden">
      <div
        class="d-flex flex-row grid-column-5"
        style="min-width: 460px; max-width: 460px;">
        <v-col
          v-bind="defaultColumnProps"
          class="grid-column-3 pr-0">
          <v-row
            no-gutters
            class="fill-height">
            <v-col
              cols="12"
              class="d-flex align-center">
              <div
                v-bind="defaultSpanProps"
                style="max-width: 15vw;">
                <v-tooltip
                  bottom>
                  <template
                    v-slot:activator="{ on, attrs}">
                    <span
                      v-bind="attrs"
                      v-on="on">
                      {{ item.financialGroup.name }}
                    </span>
                  </template>
                  <span>
                    {{ item.financialGroup.name }}
                  </span>
                </v-tooltip>
              </div>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-center">
              <div
                v-bind="defaultSpanProps"
                style="max-width: 7vw;">
                <v-tooltip
                  bottom>
                  <template
                    v-slot:activator="{ on, attrs}">
                    <span
                      v-bind="attrs"
                      v-on="on">
                      {{ item.carrier.name }}
                    </span>
                  </template>
                  <span>
                    {{ item.carrier.name }}
                  </span>
                </v-tooltip>
              </div>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-bind="defaultColumnProps"
          class="grid-column-1">
          <v-row
            no-gutters
            class="fill-height">
            <v-col
              cols="12"
              class="d-flex align-center">
              <span
                v-bind="defaultSpanProps">
                {{ item.policy }}
              </span>
            </v-col>
            <v-col
              cols="12"
              class="d-flex align-center">
              <span
                v-bind="defaultSpanProps">
                {{ item.subContractNumber }}
              </span>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-bind="defaultColumnProps"
          class="grid-column-1 fill-height d-flex align-center">
          <v-row
            no-gutters
            class="rounded-lg wineSecondary"
            style="max-width: 70px; height: 60px;">
            <v-col
              cols="12"
              class="d-flex flex-column align-center justify-center">
              <div
                v-bind="defaultSpanWhiteProps"
                class="white--text">
                Fatura
              </div>
              <div
                v-bind="defaultSpanProps"
                style="color: #E6E5E5!important">
                Prévia
              </div>
            </v-col>
          </v-row>
        </v-col>
      </div>
      <v-col
        v-bind="defaultColumnProps"
        class="grid-column-1">
        <v-row
          no-gutters
          class="fill-height">
          <v-col
            cols="12"
            class="d-flex flex-column align-start justify-center">
            <div
              v-bind="defaultSpanProps">
              {{ item.invoiceTotalLives }}
            </div>
            <div
              v-bind="defaultSpanPreviewProps">
              {{ item.previewTotalLives }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-bind="defaultColumnProps"
        class="grid-column-2">
        <v-row
          no-gutters
          class="fill-height">
          <v-col
            cols="12"
            class="d-flex flex-column align-start justify-center">
            <div
              v-bind="defaultSpanProps">
              {{ formatter.formatCurrency(formatter.calculePremium(item.invoiceValues)) }}
            </div>
            <div
              v-bind="defaultSpanPreviewProps">
              {{ formatter.formatCurrency(formatter.calculePremium(item.previewValues)) }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-bind="defaultColumnProps"
        class="grid-column-2">
        <v-row
          no-gutters
          class="fill-height">
          <v-col
            cols="12"
            class="d-flex flex-column align-start justify-center">
            <div
              v-bind="defaultSpanProps">
              {{ formatter.formatCurrency(item.invoiceValues.IOF) }}
            </div>
            <div
              v-bind="defaultSpanPreviewProps">
              {{ formatter.formatCurrency(item.previewValues.IOF) }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-bind="defaultColumnProps"
        class="grid-column-2 fill-height d-flex align-center">
        <v-row
          no-gutters
          class="wineLight rounded-lg pl-2 fill-height"
          style=" height: 60px">
          <v-col
            cols="12"
            class="d-flex flex-row align-end justify-start">
            <div
              v-bind="defaultSpanProps">
              {{ formatter.formatCurrency(formatter.calculeOutherValue(item.invoiceValues)) }}
            </div>
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-row align-start justify-space-between">
            <div
              v-bind="defaultSpanPreviewProps">
              {{ formatter.formatCurrency(formatter.calculeOutherValue(item.previewValues)) }}
            </div>
            <v-btn
              icon
              height="22"
              width="22"
              elevation="4"
              class="mr-2 rounded-circle d-flex justify-center align-center white"
              @click.stop="openInvoiceModal(item)"
              v-show="hasPermission('sdi_faturas_conferencia_valores_totais_editar')">
              <v-icon
                color="wineSecondary"
                size="17">
                edit
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-bind="defaultColumnProps"
        class="grid-column-2">
        <v-row
          no-gutters
          class="fill-height">
          <v-col
            cols="12"
            class="d-flex flex-column align-start justify-end">
            <div
              v-bind="defaultSpanProps">
              {{ formatter.formatCurrency(formatter.calculeRetroactivity(item.invoiceValues)) }}
            </div>
          </v-col>
          <v-col
            cols="12"
            class="d-flex flex-column align-start justify-start">
            <div
              v-bind="defaultSpanPreviewProps">
              {{ formatter.formatCurrency(formatter.calculeRetroactivity(item.previewValues)) }}
            </div>
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-bind="defaultColumnProps"
        class="grid-column-2 fill-height d-flex align-center">
        <v-row
          no-gutters
          class="wineSecondary rounded-lg d-flex justify-center"
          style="max-width: 170px; height: 60px;">
          <v-col
            cols="12"
            v-bind="defaultSpanWhiteProps"
            class="d-flex flex-column align-center justify-end">
            {{ formatter.formatCurrency(item.invoiceTotalValue) }}
          </v-col>
          <v-col
            cols="12"
            v-bind="defaultSpanLightProps"
            class="d-flex flex-column align-center justify-start">
            {{ formatter.formatCurrency(item.previewTotalValue) }}
          </v-col>
        </v-row>
      </v-col>
      <v-col
        v-bind="defaultColumnProps"
        class="grid-column-1 fill-height d-flex align-center"
        style="width: 200px;">
        <v-tooltip
          bottom>
          <template
            v-slot:activator="{ on, attrs }">
            <div
              v-on="on"
              v-bind="defaultSpanProps"
              class="d-flex flex-row align-center"
              :style="$vuetify.breakpoint.lg? 'width: 200px;' : ''">
              <v-sheet
                height="34"
                width="34"
                min-width="34"
                class="rounded-circle d-flex justify-center align-center"
                :color="item.checked ? '#5DB884' : 'wineTitle'">
                <v-icon
                  color="white"
                  size="15">
                  fas fa-check
                </v-icon>
              </v-sheet>
              <span
                v-bind="defaultSpanProps"
                class="pl-2">
                {{ item.checked ? 'Conferido' : 'Não conferido' }}
              </span>
            </div>
          </template>
          <span>
            {{ item.checked ? 'Conferido' : 'Não conferido' }}
          </span>
        </v-tooltip>
      </v-col>
      <v-col
        v-bind="defaultColumnProps"
        class="grid-column-1 fill-height d-flex align-center">
        <div
          class="d-flex align-center">
          <v-menu
            transition="slide-y-transition"
            offset-y>
            <template
              v-slot:activator="{ on, attrs }">
              <div
                class="wineSecondary d-flex justify-center align-center rounded-pill"
                style="height: 34px; width: 34px;"
                v-bind="attrs"
                v-on="on">
                <v-icon
                  class="white--text">
                  mdi-dots-vertical
                </v-icon>
              </div>
            </template>
            <TodoListActions :actions="getTodoActions(item)" />
          </v-menu>
        </div>
      </v-col>
    </v-row>
    <ConfirmationCommentModal
			ref="ConfirmationCommentModal"
			@confirm="confirmValidation"
			:title="'Validar registro'"
			:description="'Descreva abaixo a razão para validar este registro.'"
		/>
		<Loader :overlay="loadingOverlay" />
		<SnackbarCustomize ref="SnackbarCustomize" />
  </v-sheet>
</template>

<script>
import Formatters from '@/shared/formatters/formatters';
import Loader from '@/components/Loader/Loader.vue'
import OperationalService from '@/services-http/operational/OperationalService';
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import ConfirmationCommentModal from '../Modals/ConfirmationCommentModal/ConfirmationCommentModal.vue';
import TodoListActions from '@/components/TodoList/TodoListActions.vue';
import operationalRoutinesModule from '@/store/modules/operational-routines/operational-routines-module';
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';

export default {
	name: "ValuesTable",

  components: {
    Loader,
    SnackbarCustomize,
    ConfirmationCommentModal,
    TodoListActions
  },

	data: () => ({
    formatter: null,
    loadingOverlay: false,
    operationalService: null,

		defaultColumnProps: {
			class: "flex-item fill-height"
		},
    defaultSpanProps: {
			class: "wineLabel--text text-body-2 font-weight-regular text-truncate",
		},
		defaultSpanPreviewProps: {
			class: "wineTitle--text text-body-2 font-weight-regular text-truncate",
		},
		defaultSpanWhiteProps: {
			class: "white--text text-body-2 font-weight-regular text-truncate",
		},
    defaultSpanLightProps: {
			class: "wineLight--text text-body-2 font-weight-regular text-truncate",
		},
    defaultSpanWhiteBoldProps: {
			class: "white--text text-body-2 font-weight-bold text-truncate",
		},
	}),

	props: {
    item: null
	},

  mixins: [
    AuthorityManagementMixin
  ],

	methods: {
    openInvoiceModal(invoice) {
      this.$emit("openInvoiceModal", invoice);
    },
    async showInvoiceDetails(validationId) {
			this.$emit('showInvoiceDetails', validationId, {})
		},
    getTodoActions(item) {
      const actions = [
        {
          label: 'Ver detalhes',
          icon: 'fas fa-info-circle',
          color: 'wineLabel',
          condition: true,
          disabled: false,
          handler: () => this.showInvoiceDetails(item.validationId),
        },
        {
          label: 'Validar',
          icon: 'fa-check',
          color: 'wineLabel',
          condition: this.hasPermission('sdi_faturas_conferencia_validar'),
          disabled: item.checked,
          handler: () => this.openConfirmationCommentModal(item.validationId),
        },
      ];

      return actions;
    },
    openConfirmationCommentModal(validationDetailId) {
			this.$refs.ConfirmationCommentModal.open(validationDetailId);
		},
    async confirmValidation(obj) {
			this.loadingOverlay = true;
      const formData = new FormData();
      formData.append('validationId', obj.dataId);
      formData.append('comment', obj.comment);
      formData.append('checked', true);
			await this.operationalService.CheckValidation(formData).then(() => {
				this.$refs.SnackbarCustomize.open('success', 'Registro validado com sucesso.');
        operationalRoutinesModule.updateInvoiceValidation(obj.dataId);
      }).catch(() => {
				this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao tentar validar o registro.');
      }).finally(() => {
        this.loadingOverlay = false;
      });
		},
	},
	created() {
		this.formatter = new Formatters();
    this.operationalService = new OperationalService();
	},
}
</script>

<style>
.grid-operational-routines-container {
  display: grid !important;
  grid-template-columns: repeat(18, 1fr) !important;
  gap: 10px !important;
  justify-content: center !important;
  align-items: center !important;
}
.grid-column-5 {
  grid-column: span 5 !important;
}
.grid-column-3 {
  grid-column: span 3 !important;
}
.grid-column-2 {
  grid-column: span 2 !important;
  align-items: center !important;
}

.grid-column-1 {
  grid-column: span 1 !important;
  align-items: center !important;
}
</style>
