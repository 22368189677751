<template>
  <v-dialog
    max-width="790"
    v-model="modal">
    <template v-slot:default="dialog">
      <v-card
        class="rounded-lg">
        <v-toolbar
          width="100%"
          color="wine"
          height="79"
          style="position: relative;">
          <v-row
            class="text-center d-flex justify-center">
            <span
              class="text-h6 white--text font-weight-bold">
              Outros valores
            </span>
            <v-btn
              icon
              class="white elevation-4"
              color="wine"
              heiht="43"
              max-height="43"
              width="43"
              style="position: absolute; right: 5%; top: 20%;"
              @click="close">
              <v-icon
                size="30">
                close
              </v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
        <v-card-text
          style="height: 400px;"
          class="mt-6">
          <v-container
            fuild
            v-if="Object.entries(invoice).length > 0"
            class="mt-2 pt-2">
            <v-row
              no-gutters>
              <v-col
                cols="6">
                <span
                  :class="defaultLabelClass">
                  ISS
                </span>
              </v-col>
              <v-col
                cols="6"
                :class="defaultColumnValueClass">
                <v-text-field
                  label="Valor"
                  v-bind="defaultTextFieldProps"
                  v-model="invoice.previewValues.ISS"
                  prefix="R$"
                  v-money="money"
                  @blur="$event.preventDefault()"
                />
              </v-col>
              <v-col
                cols="6">
                <span
                  :class="defaultLabelClass">
                  IOF
                </span>
              </v-col>
              <v-col
                cols="6"
                :class="defaultColumnValueClass">
                <v-text-field
                  label="Valor"
                  v-bind="defaultTextFieldProps"
                  v-model="invoice.previewValues.IOF"
                  prefix="R$"
                  v-money="money"
                  @blur="$event.preventDefault()"
                />
              </v-col>
              <v-col
                cols="6">
                <span
                  :class="defaultLabelClass">
                  Coparticipação
                </span>
              </v-col>
              <v-col
                cols="6"
                :class="defaultColumnValueClass">
                <v-text-field
                  label="Valor"
                  v-bind="defaultTextFieldProps"
                  v-model="invoice.previewValues.RS"
                  prefix="R$"
                  v-money="money"
                  @blur="$event.preventDefault()"
                />
              </v-col>
              <v-col
                cols="6">
                <span
                  :class="defaultLabelClass">
                  2ª via de cartão
                </span>
              </v-col>
              <v-col
                cols="6"
                :class="defaultColumnValueClass">
                <v-text-field
                  label="Valor"
                  v-bind="defaultTextFieldProps"
                  v-model="invoice.previewValues.TX2"
                  prefix="R$"
                  v-money="money"
                  @blur="$event.preventDefault()"
                />
              </v-col>
              <v-col
                cols="6">
                <span
                  :class="defaultLabelClass">
                  Acertos
                </span>
              </v-col>
              <v-col
                cols="6"
                :class="defaultColumnValueClass">
                <v-text-field
                  label="Valor"
                  v-bind="defaultTextFieldProps"
                  v-model="hits"
                  prefix="R$"
                  v-money="money"
                />
              </v-col>
              <v-col
                cols="6">
                <span
                  :class="defaultLabelClass">
                  Outros Valores
                </span>
              </v-col>
              <v-col
                cols="6"
                :class="defaultColumnValueClass">
                <v-text-field
                  label="Valor"
                  v-bind="defaultTextFieldProps"
                  v-model="invoice.previewValues.OUT"
                  prefix="R$"
                  v-money="money"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
import Formatters from '@/shared/formatters/formatters';
import { VMoney } from 'v-money';
import { cloneDeep } from 'lodash';

export default {
  name: "InvoiceReviewModal",

  directives: { money: VMoney },

  data: () => ({
    modal: false,
    formatter: null,
    money: {
      decimal: ',',
      thousands: '.',
      precision: 2,
      masked: false,
    },
    hits: null,
    defaultLabelClass: "text-bidy-2 wineLabel--text font-weight-regular",
    defaultColumnValueClass: "d-flex justify-end",
    defaultTextFieldProps: {
      class: "wineLabel--text",
      outlined: true,
      dense: true,
      style: "max-width: 180px;",
      readonly: true,
    },
    invoice: {},
    loading: false,
  }),

  methods: {
    open(invoice) {
      this.setInvoice(invoice);
      this.invoice = cloneDeep(invoice);
      this.modal = true;
    },
    close() {
      this.invoice = {};
      this.modal = false;
    },
    setInvoice(invoice) {
      if (Object.entries(invoice.previewValues).length > 0) {
        Object.keys(invoice.previewValues).map((key) => {
          invoice.previewValues[key] = parseFloat(invoice.previewValues[key]).toFixed(2);
        })
			  if ('AC' in invoice.previewValues && invoice.previewValues.AC > 0) {
					this.hits = invoice.previewValues.AC;
				} else if ('AD' in invoice.previewValues && invoice.previewValues.AD < 0) {
					this.hits = invoice.previewValues.AD;
				}
			}
    },
  },

  created() {
    this.formatter = new Formatters();
  }
}
</script>

<style>
label[for="textAreaInvoiceModal"] {
  height: 110px !important;
}
</style>