<template>
  <v-dialog
    max-width="790"
    v-model="modal">
    <template v-slot:default="dialog">
      <v-card
        class="rounded-lg">
        <v-toolbar
          width="100%"
          color="wine"
          height="79"
          style="position: relative;">
          <v-row
            class="text-center d-flex justify-center">
            <span
              class="text-h5 white--text font-weight-bold">
              Críticas
            </span>
            <v-btn
              icon
              class="white elevation-4"
              color="wine"
              heiht="43"
              max-height="43"
              width="43"
              style="position: absolute; right: 5%; top: 20%;"
              @click="close">
              <v-icon
                size="30">
                close
              </v-icon>
            </v-btn>
          </v-row>
        </v-toolbar>
        <v-card-text
          style="height: 630px;"
          class="mt-5">
          <span
            class="wine--text text-h6 font-weight-regular pl-2">
            Total de erros: {{errors.length}}
          </span>
          <v-container
            fuild
            class="mt-5"
            style="max-height: 560px; overflow-y: auto;">
            <div
              v-for="(item, index) in errors"
              :key="index">
              <v-sheet
                elevation="3"
                class="pl-4 mb-6 mt-1"
                key="i">
                <v-row>
                  <v-col
                    cols="10">
                    <v-row no-gutters>
                      <v-col
                        cols="12">
                        <span
                          class="wineLabel--text text-h6 font-weight-regular">
                          {{ item }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-sheet>
            </div>
          </v-container>
          <v-spacer />
          <span
            class="wine--text text-h6 font-weight-regular pl-2"
            v-if="comments.length > 0">
            Comentários:
          </span>
          <v-container
            fuild
            class="mt-5"
            style="max-height: 560px; overflow-y: auto;"
            v-if="comments.length > 0">
            <div
              v-for="(item, index) in comments"
              :key="index">
              <v-sheet
                elevation="3"
                class="pl-4 mb-6 mt-1"
                key="i">
                <v-row>
                  <v-col
                    cols="10">
                    <v-row no-gutters>
                      <v-col
                        cols="12">
                        <span
                          class="wineLabel--text text-h6 font-weight-regular">
                          {{ item }}
                        </span>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-sheet>
            </div>
          </v-container>
        </v-card-text>
        <v-card-actions>
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>
<script>
export default {
  name: "CriticsInvoiceModal",

  data: () => ({
    modal: false,
    errors: [],
    comments: [],
  }),

  methods: {
    open(errors, comments) {
      this.errors = errors;
      this.comments = comments;
      this.modal = true;
    },
    close() {
      this.errors = [];
      this.comments = [];
      this.modal = false;
    },
  },
}
</script>