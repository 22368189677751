<template>
  <v-sheet
    height="60px"
    width="100%"
    color="wine"
    class="d-flex align-center rounded-lg white--text text-subtitle-1">
    <v-container
      fluid>
      <v-row
        class="align-center">
        <v-col
          cols="1">
          Protocolo
        </v-col>
        <v-col
          class="d-flex flex-row"
          cols="4">
          Nome do arquivo
        </v-col>
        <v-col
          cols="3">
          Grupo Financeiro
        </v-col>
        <v-col
          cols="3">
          Operadora
        </v-col>
        <v-col
          class="d-flex justify-end"
          cols="1">
          Ações
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>
<script>
export default {
  name: "ConferenceHeader"
}
</script>
