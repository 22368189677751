<template>
	<v-row>
		<v-col
			cols="12"
			class="py-0">
			<p
				class="wineLabel--text pb-0 mb-0"
				style="font-size: 1.125rem;">
				Selecione o contrato desejado:
			</p>
			<v-simple-table
				class="overflow-auto"
				style="
					max-height: 215px;
					border: 1px solid rgba(0, 0, 0, .2);">
				<thead
					class="wineSecondary">
					<tr>
						<th
							v-for="header in headers"
							:key="header.text"
							class="text-left white--text text-subtitle-1 font-weight-medium"
							:style="header.style">
							{{ header.text }}
						</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="(item, key) in invoicePreviews"
						:key="key"
						@click="selectPreview(item, key)"
						:class="{
							'text-subtitle-1 wineLabel--text font-weight-regular': true
						}"
						:style="previewInSelection(item, key) ? 'background: #EDE5EB' : ''">
						<td
							style="width: 320px">
							{{ item.id }}
						</td>
						<td
							style="width: 320px">
							{{ item.financialGroup.name }}
						</td>
						<td
							style="width: auto">
							{{ item.name }}
						</td>
						<td
							class="d-flex justify-end">
							<v-icon
								color="wine"
								v-if="previewInSelection(item, key)">
								fas fa-check
							</v-icon>
						</td>
					</tr>
				</tbody>
			</v-simple-table>
		</v-col>
		<template
			v-if="selectedPreviews.length > 0">
			<v-container
				fluid>
				<v-row>
					<v-col
						cols="12"
						class="mt-5">
						<span
							class="wineLabel--text text-subtitle-1">
							Total de prévias de faturas selecionadas:
						</span>
						<PreviewHeader />
						<PreviewTotal />
					</v-col>
				</v-row>
			</v-container>
		</template>
	</v-row>
</template>

<script>
import SnackbarCustomize from '@/components/CustomAlerts/SnackbarCustomize.vue';
import PreviewHeader from './PreviewHeader.vue';
import PreviewTotal from './PreviewTotal.vue';
import ConferenceCard from './ConferenceCard.vue';
import { mapGetters, mapMutations } from 'vuex';

export default {
	name: "InvoicePreview",

	components: {
		SnackbarCustomize,
		ConferenceCard,
		PreviewHeader,
		PreviewTotal,
	},

	data: () => ({
		headers: [
			{
				text: 'Protocolo',
				value: 'financialGroup',
				style: 'width: 320px'
			},
			{
				text: 'Grupo Financeiro',
				value: 'financialGroup',
				style: 'width: 320px'
			},
			{
				text: 'Contrato',
				value: 'contract',
				style: 'width: auto'
			},
			{
				text: '',
				value: '',
				style: 'width: auto'
			},
		],
	}),

	methods: {
		...mapMutations({
			addPreview: 'operational-routines/addPreview',
			removePreview: 'operational-routines/removePreview',
		}),
		selectPreview(preview, indexPreview) {
			if (this.selectedPreviews.some((el, index) => el.id === preview.id && el.subContractId === preview.subContractId)) {
				this.removePreview(preview);
				return;
			}

			this.addPreview(preview);
		},
		previewInSelection(preview, previewIndex) {
			return this.selectedPreviews.some((el, index) => el.id === preview.id && el.subContractId === preview.subContractId);
		},
		mergeLists(currentList, newList) {
			return [ ...currentList, ...newList ];
		},
		reduceList(key) {
			return this.selectedPreviews.reduce((currentList, item) => {
				return this.mergeLists(currentList, item[key])
			}, []);
		},
		getAllRegistryIds(key) {
			return this.reduceList(key);
		},
	},

	computed: {
		...mapGetters({
			invoicePreviews: 'operational-routines/invoicePreviews',
			selectedPreviews: 'operational-routines/selectedPreviews'
		}),
	}
}
</script>
