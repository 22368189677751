<template>
	<v-container
		fluid
		class="mb-0 pa-0 px-0">
		<v-row
			no-gutters>
			<!-- <v-col
				cols="12"
				class="mb-2">
				<div
					class="d-flex flex-row justify-end align-center">
					<span
						class="wine--text font-weight-light text-subtitle-2 mr-2">
						Ação em massa
					</span>
					<v-menu
						transition="slide-y-transition"
						offset-y>
						<template
							v-slot:activator="{ on, attrs }">
							<div
								class="wineSecondary d-flex justify-center align-center rounded-pill mr-2"
								style="height: 34px; width: 34px;"
								v-bind="attrs"
								v-on="on">
								<v-icon
									class="white--text">
									mdi-dots-vertical
								</v-icon>
							</div>
						</template>
						<TodoListActions
							:actions="bulkActions()"
						/>
					</v-menu>
					<v-checkbox
						v-model="selectAllFiles"
						color="wine"
						class="ma-0 pt-0"
						hide-details
					/>
					<span
						v-if="filesToDownload.length > 0"
						class="wine--text text-subtitle-2 font-weight-regular">
						{{ filesToDownload.length }} arquivos selecionados
					</span>
				</div>
			</v-col> -->
			<v-col
				cols="12"
				class="py-0">
				<v-data-table
					id="conferenceTable"
					class="elevation-0 transparent"
					item-key="fileId"
					:items="invoiceFiles"
					:loading="loadingTable"
					:items-per-page="itemsPerPage"
					:server-items-length="totalPages"
					@update:options="changePage($event)"
					:page="page"
					:custon-sort="{
						isDescending: true
					}"
					:footer-props="{
						itemsPerPageOptions: [10, 25, 50, 75, 100],
						disablePagination: loadingTable
					}"
          hide-default-header>
					<template v-slot:no-data>
						<span>Nenhum dado encontrado.</span>
					</template>
					<template v-slot:loading>
						<div
							class="d-flex align-center justify-center">
							<v-progress-circular
								indeterminate
								color="wine"
							/>
						</div>
					</template>
					<template
						v-slot:header>
						<v-container
							fluid>
							<v-row
								class="px-2">
								<ConferenceHeader />
							</v-row>
						</v-container>
					</template>
					<template v-slot:item="{ item }">
						<v-sheet
							min-height="50"
							min-width="1400"
							class="ma-2 elevation-3 rounded-lg d-flex align-center">
							<v-row
								no-gutters>
								<v-col
									v-bind="defaultColumnProps"
									cols="1"
									class="d-flex justify-space-between pl-4">
									<span
										v-bind="defaultSpanProps">
										{{ item.fileId }}
									</span>
								</v-col>
								<v-col
									v-bind="defaultColumnProps"
									cols="4">
									<v-sheet
										class="rounded-lg d-flex align-center px-4"
										color="wineSecondary"
										height="100%"
										width="100%">
										<v-tooltip bottom>
											<template v-slot:activator="{ on, attrs }">
												<div
													v-on="on"
													v-bind="defaultWhiteSpanProps">
													{{ item.fileName }}
												</div>
											</template>
											<p
												class="pb-0 mb-0 font-weight-bold">
												Arquivo principal
											</p>
											<span>
												{{ item.fileName }}
											</span>
											<div
												v-if="item && item.complementFilesNames && item.complementFilesNames.length > 0">
												<p
													class="mt-4 pb-0 mb-0 font-weight-bold">
													Arquivos complementares
												</p>
												<div
													v-for="(complementName, key) in item.complementFilesNames"
													:key="key">
													<span>
														{{ complementName }}
													</span>
												</div>
											</div>
										</v-tooltip>
									</v-sheet>
								</v-col>
								<v-col
									v-bind="defaultColumnProps"
									cols="3"
									class="pl-3">
									<v-tooltip
										bottom>
										<template v-slot:activator="{ on, attrs }">
											<div
												v-on="on"
												v-bind="defaultSpanProps">
												{{ item && item.financialGroup ? item.financialGroup.name : '—' }}
											</div>
										</template>
										<span>
											{{ item && item.financialGroup ? item.financialGroup.name : '—' }}
										</span>
									</v-tooltip>
								</v-col>
								<v-col
									v-bind="defaultColumnProps"
									cols="3"
									class="pl-3">
									<v-tooltip
										bottom>
										<template v-slot:activator="{ on, attrs }">
											<div
												v-on="on"
												v-bind="defaultSpanProps">
												{{ item && item.carrier ? item.carrier.name : '—' }}
											</div>
										</template>
										<span>
											{{ item && item.carrier ? item.carrier.name : '—' }}
										</span>
									</v-tooltip>
								</v-col>
								<v-col
									v-bind="defaultColumnProps"
									cols="1"
									class="pl-3 d-flex justify-end">
									<div
										class="d-flex flex-row align-center">
										<v-menu
											transition="slide-y-transition"
											offset-y>
											<template
												v-slot:activator="{ on, attrs }">
												<div
													class="wineSecondary d-flex justify-center align-center rounded-pill mr-4"
													style="height: 34px; width: 34px;"
													v-bind="attrs"
													v-on="on">
													<v-icon
														class="white--text">
														mdi-dots-vertical
													</v-icon>
												</div>
											</template>
											<TodoListActions
												:actions="getActions(item)"
											/>
										</v-menu>
										<!-- <div
											style="min-width: 34px;">
											<v-checkbox
												hide-details
												:value="item"
												v-model="filesToDownload"
												color="wine"
												class="ma-0 pa-0"
												multiple
											/>
										</div> -->
									</div>
								</v-col>
							</v-row>
						</v-sheet>
					</template>
				</v-data-table>
			</v-col>
		</v-row>

		<GenerateReportModal
			ref="GenerateReportModal"
			@confirm="confirmGenerateReport"
		/>
		<SnackbarCustomize ref="SnackbarCustomize" />
		<ConferenceModal ref="ConferenceModal" />
		<Loader :overlay="loadingOverlay" />
	</v-container>
</template>

<script>
import AuthorityManagementMixin from '@/shared/mixins/authorityManagement/authorityManagementMixin';
import OperationalRoutinesMixin from "@/shared/mixins/operationalRoutines/operationalRoutinesMixin";
import GenerateReportModal from '../Modals/GenerateReportModal/GenerateReportModal.vue';
import VerifyRoutesMixin from '@/shared/mixins/routeManagement/verifyRoutesMixin';
import SnackbarCustomize from "@/components/CustomAlerts/SnackbarCustomize.vue";
import OperationalService from '@/services-http/operational/OperationalService';
import ConferenceModal from "../Modals/ConferenceModal/ConferenceModal.vue";
import FinancialGroupsMixin from '@/shared/mixins/sdi/financialGroupsMixin';
import TodoListActions from '@/components/TodoList/TodoListActions.vue';
import DataLoadStore from '@/store/modules/data-load/data-load-module';
import DataLoadService from "@/services-http/sdi/DataLoadService.js";
import DocumentService from "@/services-http/sdi/DocumentService";
import ContractsMixin from '@/shared/mixins/sdi/contractsMixin';
import Formatters from '@/shared/formatters/formatters';
import ConferenceHeader from "./ConferenceHeader.vue";
import Loader from '@/components/Loader/Loader.vue';
import { cloneDeep } from "lodash";
import { mapGetters } from "vuex";
import moment from 'moment';

export default {
	name: "ConferenceTable",

	components: {
		GenerateReportModal,
		SnackbarCustomize,
		ConferenceHeader,
		TodoListActions,
		ConferenceModal,
		Loader,
	},

	data: () => ({
		defaultStatusSpanProps: { class: "text-truncate wineTitle--text text-subtitle-2 font-weight-regular" },
		defaultWhiteSpanProps: { class: "text-truncate white--text text-subtitle-2 font-weight-regular" },
		defaultSpanProps: { class: "text-truncate wineLabel--text text-subtitle-2 font-weight-regular" },
		defaultColumnProps: { class: "d-flex justify-start align-center" },
		operationalService: null,
		selectAllFiles: false,
		dataLoadService: null,
		documentService: null,
		loadingOverlay: false,
		filesToDownload: [],
		loadingTable: false,
		itemsPerPage: 10,
		moment: moment,
		totalPages: 0,
		page: 1,

	}),

	mixins: [
		AuthorityManagementMixin,
		OperationalRoutinesMixin,
		FinancialGroupsMixin,
		VerifyRoutesMixin,
		ContractsMixin,
	],

	props: {
		financialGroups: { type: Array },
		resetFilterConference: { type: Boolean, default: false },
    contracts: { type: Array },
    insuranceCarriers: { type: Array },
    subcontracts: { type: Array },
	},

	watch: {
		resetFilterConference: {
			deep: true,
			immediate: true,
			handler(value) {
				if (value && !this.resetFilterConference) {
					this.page = 1;
				}
			}
		},
		selectAllFiles: {
			deep: true,
			immediate: true,
			async handler(value) {
				if (value) {
					this.filesToDownload = this.invoiceFiles
				} else{
					this.filesToDownload = [];
				}
			}
		},
	},

	methods: {
		buildSearchParams(filteredParams) {
			const formattedFilterParams = cloneDeep(filteredParams)

			this.deleteEmptyParams(formattedFilterParams)

			return new URLSearchParams({
				...formattedFilterParams,
				page: this.page-1,
				size: this.itemsPerPage,
				sort: "id,desc"
			})
		},
		bulkActions() {
      const actions = [
				{
          label: 'Download',
          icon: 'fas fa-file-download',
          color: 'wineLabel',
          condition: true,
          disabled: this.filesToDownload.length === 0,
          handler: () => this.downloadAllDocumentsSelected(),
        }
      ];
      actions.sort((a, b) => a.label.localeCompare(b.label));

      return actions;
    },
		async changePage(event) {
			this.itemsPerPage = event.itemsPerPage;
      this.handlePageChange(event);
			this.updateConferenceTableScroll();
    },
		async confirmGenerateReport(data) {
			this.loadingOverlay = true;

			const params = new URLSearchParams();
    	params.append('fileId', parseInt(data.fileId));
    	params.append('fields', data.fields.map(el => el.id));
    	params.append('carrierId', data.carrierId);
    	params.append('isDefault', data.isDefault);

      await this.operationalService.GetInvoiceReport(params)
			.then((response) => {
        if (response) {
          this.processDocument(response.data, response.headers);

					this.$refs.SnackbarCustomize.open(
						'success',
						'Download realizado com sucesso.',
						3000
					);
        }
      }).catch((error) => {
				this.$refs.SnackbarCustomize.open(
					'error',
					'Ocorreu um erro ao tentar efetuar o download do relatório.');
      }).finally(() => {
        this.loadingOverlay = false;
      });
		},
		deleteEmptyParams(params) {
			Object.keys(params).forEach((key) => {
				if (["", null, undefined].some(item => item === params[key])) delete params[key]
			})
		},
		async downloadAllDocumentsSelected() {
			if (this.filesToDownload && this.filesToDownload.length === 0) return;

			this.loadingOverlay = true;

			let ids = [];

			this.filesToDownload.forEach(item => {
				ids = item && item.complementFilesIds && item.complementFilesIds.length > 0
					? [...ids, ...[ item.fileId ], ...item.complementFilesIds]
					: [...ids, ...[ item.fileId ]];
			})

      await this.dataLoadService.DownloadFileDocuments(ids).then((response) => {
        if (response) {
          this.processDocument(response.data, response.headers);

					this.$refs.SnackbarCustomize.open('success', 'Download realizado com sucesso.', 3000);
        }
      }).catch((error) => {
				this.$refs.SnackbarCustomize.open(
					'error',
					'Ocorreu um erro ao tentar efetuar o download do relatório.');
      }).finally(() => {
        this.loadingOverlay = false;
      });
		},
		async downloadDocument(item) {
			this.loadingOverlay = true;

			const ids = item && item.complementFilesIds && item.complementFilesIds.length > 0
				? [ ...[ item.fileId ], ...item.complementFilesIds]
				: [item.fileId];

      await this.dataLoadService.DownloadFileDocuments(ids).then((response) => {
        if (response) {
          this.processDocument(response.data, response.headers);

					this.$refs.SnackbarCustomize.open('success', 'Download realizado com sucesso.', 3000);
        }
      }).catch((error) => {
				this.$refs.SnackbarCustomize.open(
					'error',
					'Ocorreu um erro ao tentar efetuar o download do relatório.');
      }).finally(() => {
        this.loadingOverlay = false;
      });
		},
		async generateReport(item) {
			this.$refs.GenerateReportModal.open({carrierId: item.carrier.id, fileId: item.fileId});
		},
		getActions(item) {
      const actions = [
			{
          label: 'Gerar Relatório',
          icon: 'fas fa-file-alt',
          color: 'wineLabel',
          condition: true,
          disabled: false,
          handler: () => this.generateReport(item),
        },
				{
          label: 'Download',
          icon: 'fas fa-file-download',
          color: 'wineLabel',
          condition: true,
          disabled: false,
          handler: () => this.downloadDocument(item),
        },
				{
          label: 'Gerar Conferência',
          icon: 'fas fa-users',
          color: 'wineLabel',
          condition: true,
          disabled: false,
          handler: () => this.openConferenceModal(item),
        },
      ];
      actions.sort((a, b) => a.label.localeCompare(b.label));

      return actions;
    },
		getFilteredParams() {
			return Object.entries(this.filterConference)
				.filter(([key, value]) => value && value !== "")
				.reduce((element, [key, value]) => ({ ...element, [key]: value }), {});
		},
		async getInvoiceFiles () {
			const filteredParams = this.getFilteredParams();
			if (Object.entries(filteredParams).length === 0) return;

			this.loadingTable = true;
			this.$emit('loadSearch', true)
			const params = this.buildSearchParams(filteredParams);
			await this.operationalService.GetInvoiceFiles(params).then((res) => {
				this.totalPages = res.totalElements;
        this.loadingTable = false;
      }).catch(() => {
				this.$refs.SnackbarCustomize.open('error', 'Ocorreu um erro ao buscar os arquivos.');
      }).finally(() => {
        this.loadingTable = false;
				this.$emit('loadSearch', false)
      });
		},
		async handlePageChange(event) {
      const { page: newPage, itemsPerPage } = event;
      this.page = newPage;
      this.itemsPerPage = itemsPerPage;
      await this.getInvoiceFiles();
    },
		async handlerFilter(value) {
			this.page = 1;
			this.filterConference = cloneDeep(value);
			await this.getInvoiceFiles();
		},
		onClickBack() {
      this.redirectRouter('HomeAllMenus', { isRHProtegido: this.isRHProtegido });
    },
		openConferenceModal(item) {
			this.$refs.ConferenceModal.open(item)
		},
		processDocument(data, header) {
			const fileURL = window.URL.createObjectURL(new Blob([data]));
			const fileLink = document.createElement('a');
			const contentDispositionHeader = header['content-disposition'];
			const name = this.validateNameInResponseHeaders(contentDispositionHeader);

			fileLink.href = fileURL;
			fileLink.setAttribute('download', name);
			document.body.appendChild(fileLink);
			fileLink.click();
		},
		readBlobAsText(blob) {
			return new Promise((resolve, reject) => {
					const reader = new FileReader();
					reader.onload = function(event) {
							resolve(event.target.result);
					};
					reader.onerror = function(event) {
							reject(new Error("File could not be read: " + event.target.error));
					};
					reader.readAsText(blob);
			});
		},
		updateConferenceTableScroll() {
			const dataLoadTable = document.getElementById('conferenceTable');
			if (dataLoadTable) {
				dataLoadTable.firstElementChild.scrollTo({
					top: 0,
					behavior: 'smooth'
        });
			}
		},
		validationConferenceFile(item) {
			return item.status === 'VALID' && ['FINISHED','PARTIALLY_FINISHED'].includes(item.extProcessStatus) && ['INV', 'FAC'].includes(item.subject)
		},
		validateNameInResponseHeaders(contentDispositionHeader) {
      const fileNameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = fileNameRegex.exec(contentDispositionHeader);
      let fileName = null;
      if (matches && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }

      let name;
      if (fileName) {
        name = fileName;
      } else if (this.selectedItems.length === 1) {
        const firstSelectedDocumentId = this.selectedItems[0].id;
        const matchingDocument = this.documents.find((element) => element.id === firstSelectedDocumentId);
        name = matchingDocument ? matchingDocument.name : null;
      } else {
        name = 'filename.zip';
      }

      return name;
    },
	},
	computed: {
		...mapGetters({
			invoiceFiles: 'operational-routines/invoiceFiles'
		}),
		dataLoads() {
			return DataLoadStore.fileDataLoads;
		},
	},
	created() {
		this.dataLoadService = new DataLoadService();
		this.documentService = new DocumentService();
		this.operationalService = new OperationalService();
		this.formatter = new Formatters();
		this.loadingTable = true;
	},
}
</script>

<style>
#conferenceTable .v-data-table__progress {
  display: none;
}
</style>
