var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"pb-0",staticStyle:{"overflow-x":"auto","overflow-y":"hidden"},attrs:{"fluid":""}},[_c('v-row',{staticStyle:{"min-width":"1400px"}},[_c('v-col',{staticClass:"mt-2 pt-0 d-flex flex-row align-end",attrs:{"cols":"12"}},[_c('span',{staticClass:"wineLabel--text",staticStyle:{"font-size":"1.125rem"}},[_vm._v(" Detalhes das faturas ")]),_c('v-spacer')],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-0 transparent",staticStyle:{"min-width":"1500px"},attrs:{"id":"invocieDetailsDataTable","item-key":"id","items":_vm.invoiceByContractsSelected,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"custon-sort":{
						isDescending: true
					},"footer-props":{
						itemsPerPageOptions: [10, 25, 50, 75, 100],
						disablePagination: _vm.loading
					},"hide-default-header":""},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v("Nenhum dado encontrado.")])]},proxy:true},{key:"header",fn:function(){return [_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('ValuesHeader')],1)],1)]},proxy:true},{key:"item",fn:function({ item }){return [_c('ValuesTable',{attrs:{"item":item},on:{"openInvoiceModal":_vm.openInvoiceModal,"showInvoiceDetails":function($event){return _vm.$emit('showInvoiceDetails', $event, {})}}})]}}])})],1),_c('v-col',[(_vm.invoiceByContractsSelected.length > 0)?_c('div',{staticClass:"d-flex flex-row",staticStyle:{"min-width":"1500px"}},[_c('v-expand-x-transition',[_c('v-alert',{directives:[{name:"show",rawName:"v-show",value:(_vm.showAlert),expression:"showAlert"}],class:{
								'rounded-lg my-0': true,
							},style:(_vm.showAlert
								? 'background-color: #69ADED; max-height: 53px; width: 70%;'
								: 'background-color: #69ADED; max-height: 53px; width: 0%;'),attrs:{"id":"invoiceDetailsAlert","border":"left","colored-border":"","color":"#2E74B6","type":"info"}},[_c('v-row',{staticClass:"d-flex justify-space-between"},[_c('span',{staticClass:"white--text"},[_vm._v(" Os contratos selecionados estão vinculados a faturas diferentes. ")]),(_vm.showAlert)?_c('v-icon',{attrs:{"color":"white"},on:{"click":function($event){_vm.showAlert = false}}},[_vm._v(" close ")]):_vm._e()],1)],1)],1),_c('v-expand-x-transition',[_c('v-sheet',{class:{
								'rounded-lg white--text py-2 px-4 d-flex align-center': true,
								'ml-2 expand-enter overflow-hidden': _vm.showAlert,
								'expand-leave': !_vm.showAlert,
							},style:(_vm.showAlert
								? 'width: 100%; max-width: 30%;'
								: 'width: 100%;'),attrs:{"color":"wineSecondary","height":"53"}},[_c('v-row',{staticClass:"grid-operational-routines-container",attrs:{"no-gutters":""}},[_c('v-col',{class:{
										'grid-column-3': !_vm.showAlert
									},staticStyle:{"min-width":"460px","max-width":"460px"}},[_c('span',[_vm._v(" Total de faturas selecionadas: "+_vm._s(_vm.invoiceByContractsSelected.length)+" ")])]),(!_vm.showAlert)?_c('v-col',[_c('span',[_vm._v(" "+_vm._s(_vm.allInvoiceTotalLives)+" ")])]):_vm._e(),(!_vm.showAlert)?_c('v-col',{staticClass:"pl-1 grid-column-2"},[_c('span',[_vm._v(" "+_vm._s(_vm.allInvoicePremium)+" ")])]):_vm._e(),(!_vm.showAlert)?_c('v-col',{staticClass:"pl-4 grid-column-2"},[_c('span',[_vm._v(" "+_vm._s(_vm.allInvoiceIOF)+" ")])]):_vm._e(),(!_vm.showAlert)?_c('v-col',{staticClass:"pl-8 grid-column-2"},[_c('span',[_vm._v(" "+_vm._s(_vm.allInvoiceOutherValue)+" ")])]):_vm._e(),(!_vm.showAlert)?_c('v-col',{staticClass:"pl-10 grid-column-2"},[_c('span',[_vm._v(" "+_vm._s(_vm.allInvoiceRetroactivity)+" ")])]):_vm._e(),(!_vm.showAlert)?_c('v-col',{staticClass:"pl-14 grid-column-2"},[_c('span',[_vm._v(" "+_vm._s(_vm.allInvoiceTotalValue)+" ")])]):_vm._e()],1)],1)],1)],1):_vm._e()])],1),_c('SnackbarCustomize',{ref:"SnackbarCustomize"}),_c('CriticsInvoiceModal',{ref:"CriticsInvoiceModal",attrs:{"hasComment":false}}),_c('InvoiceModal',{ref:"InvoiceModal",attrs:{"hasComment":true,"filter":_vm.filter}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }